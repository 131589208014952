// import * as ot from '../Type/orderTypes';
import {
  FETCH_ORDER,
  ORDER_REQUEST,
  ORDER_FAIL,
  ORDER_UPDATE,
  ORDER_PROCESS_COOKING,
  ORDER_PRIORITY_CHANGE,
  ORDER_CANCEL,
  REMOVE_ITEM,
  FETCH_SESSION_ORDER,
  DISH_PRIORITY,
  FETCH_SINGLE_ORDER,
  SINGLE_ORDER_REQUEST,
  BUMP_ORDER,
  GET_COURSES,
  SELECT_STATION,
  BAR_LOADER,
  REMOVE_SINGLE_ORDER
} from "../Type/orderTypes";
import { CHANGE_THE_STATION } from "../Type/stationTypes";

const initialState = {
  orders: [],
  updateStatus: [],
  loading: false,
  stChange: [],
  processChange: [],
  priortyChange: [],
  cancelOrder: [],
  removeItem: [],
  sessionOrders: [],
  dishPriority: [],
  singleOrder: [],
  bumporder: [],
  singleLoading: false, //for single orders
  courses: [],
  barLoading:false,
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_REQUEST:
      return {
        ...state,
        singleLoading: false,
        loading: true,
        isAuthenticated: false,
      };
    case SINGLE_ORDER_REQUEST:
      return {
        ...state,
        loading:false,
        singleLoading: true,
        isAuthenticated: false,
      };

    case FETCH_ORDER:
      return {
        ...state,
        loading: false,
        singleLoading:false,
        orders: action.payload,
      };
    case FETCH_SINGLE_ORDER:
      let tempOrder =[];
      let {orders}=state
      tempOrder = JSON.parse(JSON.stringify(orders))
      let orderIndex = tempOrder.findIndex(item=>item._id === action.payload._id)
      tempOrder[orderIndex]=action.payload
      return {
        ...state,
        singleLoading: false,
        loading:false,
        singleOrder: action.payload,
        orders:tempOrder
      };
    case ORDER_UPDATE:
      return {
        ...state,
        loading: false,
        singleLoading:false,
        updateStatus: action.payload,
      };
    case CHANGE_THE_STATION:
      return {
        ...state,
        loading: false,
        stChange: action.payload,
      };
    case ORDER_PROCESS_COOKING:
      return {
        ...state,
        loading: false,
        processChange: action.payload,
      };
    case ORDER_PRIORITY_CHANGE:
      return {
        ...state,
        loading: false,
        priortyChange: action.payload,
      };
    case ORDER_CANCEL:
      return {
        ...state,
        loading: false,
        cancelOrder: action.payload,
      };
    case ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REMOVE_ITEM:
      return {
        ...state,
        loading: false,
        removeItem: action.payload,
      };
    case FETCH_SESSION_ORDER:
      return {
        ...state,
        loading: false,
        sessionOrders: action.payload,
      };
    case DISH_PRIORITY:
      return {
        ...state,
        loading: false,
        dishPriority: action.payload,
      };
    case BUMP_ORDER:
      return {
        ...state,
        loading: false,
        bumporder: action.payload,
      };
    case GET_COURSES:
      return {
        ...state,
        loading: false,
        courses: action.payload,
      };
    case BAR_LOADER:
      return{
        ...state,
        barLoading:action.payload,
      };
      case REMOVE_SINGLE_ORDER:
        let allOrders=state.orders;
        let index = allOrders.findIndex(item=>item._id === action.payload)
       allOrders.splice(index,1)
      return{
        ...state,
        singleLoading: false,
        loading:false,
        singleOrder: [],
        orders:allOrders
      };


    default:
      return state;
  }
};
