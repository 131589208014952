import React from "react";


const SubHeader = () => {
    return (
        <>

            <div className="row orderheader">
                <div className="col-lg-3">
                    <div className="tableHeader">
                        <ul>
                            <li>
                                <img src="/img/icons/BILL_WHITE.png" alt="icon" />
                                order
                            </li>
                            <li>
                                <img src="/img/icons/TABLE_WHITE.png" alt="icon" />
                                table
                            </li>
                            <li>
                                <img src="/img/icons/TARGET_WHITE.png" alt="icon" />
                                class
                            </li>
                            {/* <li>
                                <img src="/img/icons/QR_WHITE.png" alt="icon" />
                                input
                            </li> */}
                        </ul>
                    </div>
                </div>

                <div className="col-lg-7">
                    <div className="tableHeader">
                        <ul>
                            <li className="detailtext">
                                details
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="tableHeader">
                        <ul>
                            <li>
                                Status
                            </li>
                            <li>
                                time
                            </li>
                            <li>
                                delay
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SubHeader;