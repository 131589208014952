import { NavLink, ListGroupItem, ListGroup } from "reactstrap";
// import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { useEffect } from "react";
import { getSingleRecipe } from "../../../store/actions/recipeAction";
// import { singleRecipe } from '../../../DummyData/singleRecipe';

const List = ({ recipesData, setActiveRecipe }) => {
	const dispatch = useDispatch();


	// useEffect(()=> {
	// 	getList();
	// }, [params.id])



	// const recipeList = [
	// 	{
	// 		id: 234,
	// 		name: "RISOTTO",
	// 		list: [
	// 			{
	// 				name: "RISSOTO AL FUNGHI",
	// 				recipe: ["3 table spoon sugar", "5 grams flour", "2 CUP ONION", "PASTA", "PARMESAN TOP"]
	// 			},
	// 			{ name: "RISSOTO MILANESE" },
	// 			{ name: "RISSOTO SPECK" },
	// 			{ name: "RISSOTO FORMAGGIO" },
	// 			{ name: "RISSOTO SPUMANTE" }
	// 		]
	// 	},
	// 	{
	// 		id: 235,
	// 		list: [
	// 			{ name: "RISSOTO AL FUNGHI" },
	// 			{ name: "RISSOTO MILANESE" },
	// 			{ name: "RISSOTO SPECK" },
	// 			{ name: "RISSOTO FORMAGGIO" },
	// 			{ name: "RISSOTO SPUMANTE" }
	// 		]
	// 	},
	// 	{
	// 		id: 236,
	// 		list: [
	// 			{ name: "PASTA AL FUNGHI" },
	// 			{ name: "PASTA MILANESE" },
	// 			{ name: "PASTA SPECK" },
	// 			{ name: "PASTA FORMAGGIO" },
	// 			{ name: "PASTA SPUMANTE" }
	// 		]
	// 	},
	// ];

	// const params = useParams();

	// console.log(JSON.stringify(params.id), "params here");

	// const recipe = recipeList.find(({ id }) => id === 235);
	// const recipe = recipesData.find(({ id }) => id === 235);

	// console.log("found this one: ", JSON.stringify(recipe))

	return (
		<>
			<ListGroup >
				{recipesData?.map((recipe) => {
					return (
						<>
							<ListGroupItem className="recipe-list-btns">
								<NavLink onClick={() => {
									dispatch(getSingleRecipe(recipe?.recipe_id))
									// setRecipeName(recipe?.recipe_name)
									setActiveRecipe({ id: recipe?.recipe_id, name: recipe?.recipe_name })
								}}>
									<h5 className="text-dark ">{recipe?.recipe_name} &gt;</h5>
								</NavLink>
							</ListGroupItem>
						</>
					)
				})}
			</ListGroup>
		</>
	)
}

export default List;