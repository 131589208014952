import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Col, Input, InputGroup, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { setBusinesse } from "../../store/actions/authAction";

const BusinessModal = ({ openBidModal, setOpenBidModal, allBussiness }) => {
const dispatch = useDispatch();
    const [selectedBussines, setSelectedBussines] = useState('')

    const changBussinessHandler = ((e) => {
        setSelectedBussines(e.target.value)
    })

    const setBusinessFunc=()=>{
        dispatch(setBusinesse(selectedBussines))
        setOpenBidModal(false) 
    }
    const closeModalFun =()=>{
        if(localStorage.getItem("blId")){
            setOpenBidModal(false)
        }
    }

    return (
        <Modal
            centered
            // fullscreen="sm"

            size="md"
            onHide={() => { setOpenBidModal(false) }}
            toggle={() => { setOpenBidModal(false) }}
            isOpen={openBidModal}
        >

            <div className=" text-dark d-flex align-items-center justify-content-between py-1 px-3">
                <div className="d-flex align-items-center">
                    <img src="/img/icons/operate_logo.png" alt="operate-logo" height={50} width={30} />
                    <h4 className="mb-0 mx-2">Business </h4>
                </div>
                <div
                    onClick={() =>
                        closeModalFun()
                    }>  <img
                        src="/img/icons/DENY_BLACK.png"
                        alt="reject"
                        height={25}
                    /></div>
            </div>
            <ModalBody>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <div className="msg-wrapper px-3">
                            <h5>{allBussiness?.businessName}</h5>
                            <InputGroup className="input-group-alternative">
                                <Input required type="select" size={"sm"} className="input-select"
                                    name="select" onChange={changBussinessHandler}>
                                    <option selected value="">Select</option>
                                    {
                                        allBussiness?.businessLocations?.map((item) => {
                                          
                                            return (
                                                <option value={item?.blId}>{item?.blName}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
            </ModalBody>

            <ModalFooter className="justify-content-center">
                <Button outline color="success" onClick={()=>{setBusinessFunc()}}>OK</Button>
                {/* <Button outline color="success" onClick={() => { saveNotfication(notifyMsg) }}>Save</Button>{' '} */}

            </ModalFooter>

        </Modal>
    )
}


export default BusinessModal;