import React, { useState } from "react";
import ModalInterface from "../../../ModalInterface";
import { useDispatch } from "react-redux";
import { ProcessCookingAction } from "../../../../store/actions/orderAction";

const StartCookModal = (props) => {
  const dispatch = useDispatch();
  const CookingStartFunc = (_updatedStatus) => {
    if (props?.saleEntry?.status !== _updatedStatus) {
      dispatch(
        ProcessCookingAction({
          order_id: props?.cardId,
          item_id: props?.saleEntry?._id,
          status: _updatedStatus,
        })
      );
      props?.setStartCooking(false);
      props?.setUpdateOrder();
      // props?.handleClose()
    }
  };

  return (
    <>
      <ModalInterface
        display={props?.startCooking}
        //   setDisplay={setDisplay}
        closeEvent={() => {
          props?.setStartCooking(false);
          // props?.handleClose()
          props?.setSelected("");
        }}
        className={"text-center"}
        size={"md"}
        content={
          <>
            <div className="d-flex justify-content-center">
              <div className="mt-2 ">
                {/* <div
                  className="btn-yellow-modal mt-2 position-absoulte"
                  onClick={() => {
                    props?.setStartCooking(false);
                    // props?.handleClose()
                  }}
                >
                  <img
                    src="/img/icons/DENY_BLACK.png"
                    alt="reject"
                    className="mt-2 "
                    height={25}
                  />
                  <span>CANCEL</span>
                </div> */}
                {/* {  saleEntry.status === "cooked" ?

                <div className="btn-yellow-modal mt-2" onClick={() => {CookingStartFunc("cooked")}}>
                  <img
                    src="/img/icons/BUMP_GRAY.png"
                    alt="reject"
                    className="mt-2"
                    height={30}
                  />
                  <span>Cooked</span>
                </div>
:""} */}
                {props?.saleEntry?.status === "pending" ? (
                  <div
                    className="btn-yellow-modal mt-2"
                    onClick={() => {
                      CookingStartFunc("cooking");
                    }}
                  >
                    <div>
                      <img
                        src="/img/icons/BUMP_BLACK.png"
                        alt="reject"
                        className="mt-2"
                        height={30}
                      />
                    </div>
                    <span>Cooking</span>
                  </div>
                ) : (
                  ""
                )}
                {props?.saleEntry?.status === "cooking" ? (
                  <>
                    <div
                      className="btn-yellow-modal mt-2"
                      onClick={() => {
                        CookingStartFunc("pending");
                      }}
                    >
                      <div>
                        <img
                          src="/img/icons/DENY_BLACK.png"
                          alt="reject"
                          className="mt-2"
                          height={30}
                        />
                      </div>
                      <span>STOP</span>
                    </div>
                    <div
                      className="btn-yellow-modal mt-2"
                      onClick={() => {
                        CookingStartFunc("cooked");
                      }}
                    >
                      <img
                        src="/img/icons/BUMP_GRAY.png"
                        alt="reject"
                        className="mt-2"
                        height={30}
                      />
                      <span>Cooked</span>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        }
        title={
          <span>
            <img src="/img/icons/BUMP_BLACK.png" height={30} />
            {props?.saleEntry?.status === "cooked" ? (
              <>This dish has been cooked.</>
            ) : (
              <>Cooking Pocess</>
            )}
          </span>
        }
      />
    </>
  );
};

export default StartCookModal;
