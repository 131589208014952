import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Navbar,
  NavbarToggler,
  NavbarText,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "../../assets/css/Topnavbar.css";
import { useLocation } from "react-router-dom";
import { getAllStations } from "../../store/actions/stationAction";
import { useDispatch, useSelector } from "react-redux";

const TopNav = ({ active, setActive }) => {
  // const Items = ["PASTA", "RISOTTO", "INSALATA", "PINSA", "BAR"];
  const [hoverIcon, setHoverIcon] = useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const { stations } = useSelector((state) => state?.stations);
  const userStations = useSelector(
    (state) => state?.authUser?.user?.data?.stations
  );
  useEffect(() => {
    dispatch(getAllStations());
    if (
      location.pathname === "/admin/dashboard" ||
      location.pathname === "/employee/dashboard"
    ) {
      setActive("live");
    }
  }, []);

  const navigateToALLfunc = () => {
    if (location.pathname.includes("employee")) {
      navigate("/employee/dashboard", {
        state: { menu: "all" },
      });
    } else {
      navigate("/admin/dashboard", {
        state: { menu: "all" },
      });
    }
  };
  return (
    <>
      <Navbar
        dark
        expand="md"
        fixed="top"
        full
        className="header-top pt-0 pb-0"
      >
        <div className="logo-div d-flex">
          <img
            src="/img/operate-logo.png"
            alt="Logo"
            className="img-responsive me-5"
            height={60}
            width={80}
          />
          <div
            className={
              active === "live" ? "live-btn active" : "live-btn sliver"
            }
            onClick={() => {
              setActive("live");
              navigateToALLfunc();
            }}
          >
            {" "}
            <div
              onMouseEnter={() => {
                setHoverIcon("live");
              }}
              onMouseLeave={() => {
                setHoverIcon(-1);
              }}
              className="d-flex justify-content-center mt-1"
              // to={
              //   location.pathname.includes("employee")
              //     ? "/employee/dashboard"
              //     : "/admin/dashboard"
              // }
              //     onClick={()=>
              // navigateToALLfunc()}
            >
              <img
                src={
                  active === "live"
                    ? "/img/icons/LIVE_WHITE.png"
                    : `/img/icons/LIVE_${
                        hoverIcon === "live" ? "WHITE" : "BLACK"
                      }.png`
                }
                alt="Logo"
                height={50}
                width={50}
              />
            </div>
          </div>
          {location.pathname.includes("employee") ? (
            <div
              className={
                active === "recipes"
                  ? "topCenter-btns active m-5"
                  : "topCenter-btns sliver m-5"
              }
              onClick={() => {
                setActive("recipes");
              }}
            >
              <Link
                className="recipe-btn"
                to={"/employee/dashboard/recipe-list"}
              >
                RECIPIES
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>

        <NavbarToggler onClick={function noRefCheck() {}} />
        <Collapse navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink>
                <div className="logo-div d-flex">
                  {userStations?.map((item, index) => {
                    return (
                      <>
                        <div
                          className={
                            active === index
                              ? "topCenter-btns active  text-uppercase"
                              : "topCenter-btns sliver  text-uppercase"
                          }
                          onClick={() => {
                            setActive(index);
                            {
                              location.pathname.includes("employee")
                                ? navigate("/employee/dashboard/single-order", {
                                    state: { menu: item },
                                  })
                                : navigate("/admin/dashboard/single-order", {
                                    state: { menu: item },
                                  });
                            }
                          }}
                        >
                          <span> {item} </span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </NavLink>
            </NavItem>
          </Nav>

          <div
            className={
              active === 1
                ? "topCenter-btns active m-5"
                : "topCenter-btns sliver m-5"
            }
            onClick={() => {
              setActive(1);
            }}
          >
            {}
          </div>

          <NavbarText className="ms-auto ">
            <div className="logo-div d-flex justify-content-center">


            <div
                onClick={() => {
                  setActive("kel");
                  location.pathname.includes("employee")
                    ? navigate("/employee/dashboard/tables")
                    : navigate("/admin/dashboard/tables");
                }}
                onMouseEnter={() => {
                  setHoverIcon("kel");
                }}
                onMouseLeave={() => {
                  setHoverIcon(-1);
                }}
                className={
                  active === "kel" ? "live-btn active mx-3" : "live-btn sliver mx-3"
                }
              >
                KELLNER
              </div>
              {location.pathname.includes("employee") ? (
                <div
                  className={
                    active === 7 ? "live-btn active" : "live-btn sliver"
                  }
                  color="dark"
                  onMouseEnter={() => {
                    setHoverIcon(2);
                  }}
                  onMouseLeave={() => {
                    setHoverIcon(-1);
                  }}
                  onClick={() => {
                    setActive(7);
                    navigate("/employee/dashboard/order-list", {
                      state: { menu: "all" },
                    });
                  }}
                >
                  <img
                    src={
                      active === 7
                        ? "/img/icons/PROGR_WHITE.png"
                        : `/img/icons/PROGR_${
                            hoverIcon === 2 ? "WHITE" : "BLACK"
                          }.png`
                    }
                    alt="Logo"
                    className="mt-1 "
                    height={40}
                    width={40}
                  />
                </div>
              ) : (
                ""
              )}

   

              {location.pathname.includes("admin") ? (
                <div
                  onClick={() => {
                    navigate("/admin/dashboard/order-list", {
                      state: { menu: "all" },
                    });
                  }}
                  onMouseEnter={() => {
                    setHoverIcon(3);
                  }}
                  onMouseLeave={() => {
                    setHoverIcon(-1);
                  }}
                  className={
                    active === 8 ? "live-btn active" : "live-btn sliver"
                  }
                >
                  <div onClick={() => setActive(8)}>
                    <img
                      src={
                        active === 8
                          ? "/img/icons/ORDERLIST_WHITE.png"
                          : `/img/icons/ORDERLIST_${
                              hoverIcon === 3 ? "WHITE" : "BLACK"
                            }.png`
                      }
                      alt="Logo"
                      height={40}
                      width={40}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </NavbarText>
          {/* <NavbarText>
                        NotePad
                    </NavbarText> */}
        </Collapse>
      </Navbar>
    </>
  );
};

export default TopNav;
