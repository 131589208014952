import React, { useEffect } from "react";
import OcardHeader from "./ocardHeader";
import "./ocard.css";
import OrderFooter from "./ofooter";
import { useSelector, useDispatch } from "react-redux/es/exports";
import {
  bumpOrderAction,
  ProcessCookingAction,
  UpdateOrder,
} from "../../../store/actions/orderAction";
import { STEPS_MODAL_OPEN } from "../../../store/types";
import { changeBumpStatus } from "../../../store/actions/tableSessionsAction";
import SelectStation from "../../Modals/selectStation";
import { Spinner } from "reactstrap";

// import { Spinner } from "reactstrap";

const Ocard = ({
  data,
  dish,
  setStationSelect,
  setOrderObj,
  setUpdateOrder,
  SingleLoading,
}) => {
  const order = data.find((product) =>
    product.salesEntries.some((item) => item._id === dish?._id)
  );

  const ordrIDStore = useSelector((state) => state?.info?.id.stationName?._id);
  const dispatch = useDispatch();
  const cookingFunc = () => {
    let status = dish?.status;
    if (status === "cooked" || dish?.payment_status === "half_paid" || dish?.payment_status === "paid") {
      alert("This dish has been prepared.");
    } else {
      if (dish?.status === "cooking") {
        setStationSelect(true);
        setOrderObj({
          order,
          dish,
        });
      } else if (dish?.status === "pending")
        dispatch(
          ProcessCookingAction({
            order_id: order?._id,
            item_id: dish?._id,
            status: "cooking",
          })
        );
    }
    setUpdateOrder({
      _id: order?._id,
      dish_id: dish?._id,
      businessLocationId: order?.businessLocationId,
    });
  };


  return (
    <>
      <div className="o-card">
        <div className="content-card">
          <div
            className={ordrIDStore === dish?._id ? "o-card-overlay" : "abc"}
          ></div>
          <div className="d-block">
            {/* header start */}
            <OcardHeader
              dish={dish}
              order={order}
              tableNo={order?.tableNumber}
              // setSingleOrder={setSingleOrder}
              setUpdateOrder={(obj)=>{setUpdateOrder(obj)}}
            />
            {/* header fin */}
          </div>
          {SingleLoading === true ? (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="d-block">
                <div className="takeaway-sec d-flex justify-content-between">
                  <span>{order?.order_type?.toUpperCase()}</span>
                  <span>
                    {order?.sessionName !== null ? order?.sessionName : "12:45"}
                  </span>
                </div>
                <div
                  className={
                    dish?.priority === "urgent"
                      ? "item-sec bghotpink d-flex align-items-center justify-content-between pl-2"
                      : "item-sec d-flex align-items-center justify-content-between pl-2"
                  }
                >
                  <span
                    className="item-name-ocard f-14 px-1"
                    onClick={() => {
                      cookingFunc();
                    }}
                  >
                    {dish?.itemName !== null
                      ? dish?.itemName?.toUpperCase()
                      : "KAMUT BASE"}
                  </span>
                  <span>{dish?.station?.toUpperCase()}</span>
                </div>
              </div>
              <div className=" order-body">
                <div className="dishName d-block p-2">
                  {dish?.subLineItems.map((sl, index) => {
                    return (
                      <>
                        {sl.type === "Standard" && sl.isSingleOnly === true ? (
                          <>
                            <div className="d-flex ">
                              <img
                                src="/img/icons/MIDARROWRIGHT_BLACK.png"
                                className="pt-1"
                                height={20}
                                alt="item-logo"
                              />

                              <span className="f-14 px-1">{sl?.itemName}</span>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </div>

                {dish?.redItems?.length > 0 && dish?.greenItems?.length > 0 ? (
                  <div className="two-card-sec d-flex justify-content-between">
                    <div className="block-card bg-pink">
                      <span>
                        {" "}
                        <img
                          src="/img/icons/MINUS_BLACK.png"
                          alt="minus"
                          height={12}
                        />
                      </span>
                      {dish?.redItems?.map((item, key) => {
                        return (
                          <div className="card-porduct pl-2">
                            <span className="d-block ">{item?.itemName} </span>
                          </div>
                        );
                      })}
                    </div>
                    <div className="block-card bg-lightGreen">
                      <span>
                        {" "}
                        <img
                          src="/img/icons/PLUS_BLACK.png"
                          alt="minus"
                          height={12}
                        />
                      </span>
                      {dish?.greenItems?.map((item, key) => {
                        return (
                          <div className="card-porduct pl-2">
                            <span className="d-block ">{item?.itemName} </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {dish?.steps.length === 0 ? (
                  ""
                ) : (
                  <div className="ingredients-sec my-2 ">
                    <div
                      className="bill-img"
                      onClick={() => {
                        dispatch({
                          type: STEPS_MODAL_OPEN,
                          payload: dish?.steps,
                        });
                      }}
                    >
                      <img
                        src="/img/icons/BILL_GRAY.png"
                        height={20}
                        alt="bill"
                      />
                    </div>
                    {dish?.ingredients?.map((ing, key) => {
                      return (
                        <div className="ing-name px-2">
                          <span className=" d-block">
                            <span>{parseInt(ing?.quantity)} -</span> {ing?.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          )}

          <div className=" d-block align-items-end footer">
            <OrderFooter
              // setCookingModalObj={setCookingModalObj}
              // setCookingModalOpen={setCookingModalOpen}
              order={order}
              dish={dish}
              cooked={
                dish?.status === "cooked" || dish?.status === "half_paid"
                  ? "bg-green"
                  : dish?.status === "cooking"
                  ? "bg-yellow"
                  : "bg-gray"
              }
              cookingFunc={cookingFunc}
              createdAt={order?.createdAt}
              cookingStarted={
                order?.cooking_started ? order?.cooking_started : false
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ocard;
