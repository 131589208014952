import { LOGIN, LOGOUT,LOADING,FAIL_ACCESS } from '../types';

const initialState = {
	user: null,
	uid: null,
	loading: false
};

/*
    Any action related to Profile will go here.
*/

export default function authReducer(state = initialState, { type, payload }) {
	switch (type) {
		case LOADING:
		return{
			...state,
			loading:true,
		}
		case FAIL_ACCESS:	
			return{
				...state,
				loading:false,
			}
		case LOGIN:
			return {
				...state,
				loading:false,
				user: payload,
				uid: payload.token,
			};
		case LOGOUT:
			console.log('logout reducer');
			return {
				...state,
				loading:false,
				user: null,
				uid: null,
			};
		default:
			return { ...state };
	}
}
