import React,{useEffect} from "react";
import "../../assets/css/OrderList.css";
import SubHeader from "../../components/Dashboard/OrderListComponents/SubHeader";
import { Spinner } from "reactstrap";
import OrderContainer from "../../components/Dashboard/OrderListComponents/OrderContainer";
// import OLsecondContainer from "../../components/Dashboard/OrderListComponents/OlSecContainer";
import MangeModal from "../../components/Dashboard/Home/MangeModal/manage";
import ChangeStationModal from "../../components/Dashboard/Home/ChangeStationModal/changeStation";
import { getOrders } from "../../store/actions/orderAction";
import { useDispatch,useSelector } from "react-redux";
import { CLEAR_ID } from "../../store/types";


const OrderList = () => {
const dispatch= useDispatch();

  const loading = useSelector((state) => state?.orders.loading);
  const data = useSelector((state) => state.orders.orders);
  const orderUpdated = useSelector((state) => state?.orders?.updateStatus);
  const StUpdate = useSelector((state) => state?.orders?.stChange);
  const StatusChange = useSelector((state) => state?.orders?.processChange);
  const changePriority = useSelector((state) => state?.orders?.priortyChange);
  const cancelResponse = useSelector((state) => state?.orders?.cancelOrder);
  const removeitem = useSelector((state) => state?.orders?.removeItem);
  const dishPriority = useSelector((state)=> state?.orders?.dishPriority);

  // useEffect(() => {
  //   dispatch(getOrders());

  // }, []);
    // ORDER UPDATE STATUS CHECK OR STATION UPDATE
    useEffect(() => {
      if (
        orderUpdated?.success === true ||
        StUpdate?.success === true ||
        StatusChange.success === true ||
        changePriority.success === true ||
        cancelResponse.success === true ||
        removeitem.success === true ||
        dishPriority?.success === true
      ) {
        dispatch(getOrders());
        dispatch({type:CLEAR_ID})
      }
    }, [
      orderUpdated,
      StUpdate,
      StatusChange,
      changePriority,
      cancelResponse,
      removeitem,
      dishPriority
    ]);

      // Loading Div
  if (loading) {
    return (
      <>
         <div className="d-flex position-absolute top-50 start-50">
          <Spinner/>
        </div>
      </>
    );
  }

  return (
    <>
          <SubHeader />
          <OrderContainer data={data} />
       {/* <HomeView/> */}
          {/* <OLsecondContainer /> */}
        
 
      <MangeModal />
      <ChangeStationModal  />
    </>
  );
};

export default OrderList;
