import * as servicestypes from "../Type/ServicesTypes";
const initialState = {
    services: [],
    loading: false,
    saveServices: [],
    error: []
};

export const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case servicestypes.LOADING:
            return {
                ...state,
                loading: true,

            }

        case servicestypes.FETCH_SERVICES_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                services: action.payload
            }

        case servicestypes.SAVE_SERVICE_NOTIFICATION:
            return {
                ...state,
                loading: false,
                saveServices: action.payload
            }
        case servicestypes.FAIL_SERVICES:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        default:
            return state
    }
}

