
//=========================ID Type ==================================
 export const GET_ID ="GET_ID";
 export const CLEAR_ID ="CLEAR_ID";
 export const ORDER_ID ="ORDER_ID";
 export const CLEAR_ORDER_ID = "CLEAR_ORDER_ID";

 // ===================== MODAL TYPES ==============================
export const STATION_OPEN_MODAL = "STATION_OPEN_MODAL"
export const STATION_CLOSE_MODAL = "STATION_CLOSE_MODAL"

export const MANAGE_ITEM_OPEN_MODAL = "MANAGE_ITEM_OPEN_MODAL"
export const MANAGE_ITEM_CLOSE_MODAL = "MANAGE_ITEM_CLOSE_MODAL"


export const STEPS_MODAL_OPEN="STEPS_MODAL_OPEN"
export const STEP_MODAL_CLOSE="STEPS_MODAL_CLOSE"

export const MERGE_MODAL ="MERGE_MODAL"
export const CLEAR_CHANGE_MERGE_MODAL ="CLEAR_CHANGE_MERGE_MODAL"
//===========================================================
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOADING='LOADING';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const FAIL_ACCESS= 'FAIL_ACCESS';
export const AUTH_SET_LOADING = 'AUTH_SET_LOADING';
export const AUTH_NOTIFICATION = 'AUTH_NOTIFICATION';
export const GET_BUSINESSID='GET_BUSINESSID';
export const SET_BUSINESS ="SET_BUSINESS"
///==============Station Types===================///

export const STATION_REQUEST='STATION_REQUEST';
export const FETCH_STATIONS= 'FETCH_STATION';
export const STATION_FAIL='STATION_FAIL';
export const CHANGE_THE_STATION="CHANGE_THE_STATION";

///==============RECIPE Types===================///
export const RECIPE_REQUEST='RECIPE_REQUEST';
export const RECIPE_FETCH='RECIPE_FETCH';
export const RECIPE_SINGLE_FETCH='RECIPE_SINGLE_FETCH';
export const RECIPE_FAILL="RECIPE_FAILL";
export const RECIPE_RESET="RECIPE_RESET";

///===============TABLE_SESSIONS Types================///
export const FETCH_TABLES ="FETCH_TABLES";
export const FETCH_SESSIONS='FETCH_SESSIONS';
export const SESSIONS_REQUEST='SESSIONS_REQUEST';
export const SESSIONS_FAIL='SESSIONS_FAIL';
export const TABLE_CHANGE="TABLE_CHANGE";
export const TABLE_MERGE="TABLE_MERGE";