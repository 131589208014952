import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Pagination, PaginationItem, PaginationLink, Row, Spinner } from "reactstrap";
import { getServiceNotification } from "../../../store/actions/servicesAction";
import ErrorAlert from "../../Modals/errorAlert";

const NotificationServices = () => {
    const dispatch = useDispatch();
    const [warn, setWarn] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const busLocationID = localStorage.getItem("blID") || 660076343853236;
    const services = useSelector((state) => state?.services?.services)
    const loading = useSelector((state) => state?.services?.loading)

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        getMessages(pageNumber);
    }, [])
    function getMessages(pageno) {
        dispatch(getServiceNotification(`id=${busLocationID}&page=${pageno}&size=${6}`))
    }
    const numberofPages = () => {
        let element = []
        for (let i = 0; i < services?.totalPages; i++) {

            const jsxEleemnt = <PaginationItem>
                <PaginationLink href="#" onClick={() => { jumpNumberPage(i) }}>
                    {i + 1}
                </PaginationLink>
            </PaginationItem>
            element.push(jsxEleemnt)

        }
        return element
    }
    const jumpNumberPage = (selectedPage) => {
        if (services.totalPages !== pageNumber) {
            setPageNumber(selectedPage)
        }
    }
    const nextPage = () => {

        if (services?.totalPages > pageNumber) {
            setPageNumber(pageNumber + 1)
        }
        else {
            setAlertMsg("Sorry Records are lesser.")
            setWarn(true)

        }   
    }
    const prviousPage = () => {
        if (pageNumber <= 0) {
            setAlertMsg("No More Data.")
            setWarn(true)
        }
        else {
            setPageNumber(pageNumber - 1)
        }
    }

    useEffect(() => {
        getMessages(pageNumber);
    }, [pageNumber])
    if(loading){
        return(
            <div className="mt-5 d-flex justify-content-center align-items-center">
            <Spinner/>
            </div>
        )
    }
    return (
        <>
            <Row className="p-3">
                <Col lg="12" md="12" sm="12" xs="12" >
                    <div className="py-3">
                        <h3>Service Notification</h3>
                    </div>
                    <Row >
                        {services?.notification?.map((item, index) => {
                            console.log(item?.sessionID.length)
                            return (
                                <Col lg="4" md="4" sm="6" xs="12" className="p-3">
                                    <Card
                                        className="my-2"
                                        color="light"
                                    >
                                        <CardHeader>
                                            <div className="d-flex justify-content-between">
                                                <div>  {item?.blId}</div>
                                                <div>{item?.sessionID}</div>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <CardTitle tag="h5">
                                                Table No {item?.table}
                                            </CardTitle>
                                            <CardText>
                                                {item?.message}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })
                        }
                    </Row>
                </Col>
              { services?.totalPages > 1 ? 
                <div className="p-3 d-flex justify-content-end">
                    <Pagination>
                        <PaginationItem>
                            <PaginationLink
                                first
                                href="#"
                                onClick={() => { prviousPage() }}
                            />
                        </PaginationItem>

                        {numberofPages()}

                        <PaginationItem>
                            <PaginationLink
                                href="#"
                                last
                                onClick={() => { nextPage() }}

                            />
                        </PaginationItem>
                    </Pagination>
                </div>:""
}
            </Row>
            <ErrorAlert alert={warn} setAlert={setWarn} alertMessage={alertMsg} />
        </>
    )
}

export default NotificationServices;