import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { employeeSettings } from "../../assets/sliderSetting";
import { Row, Col, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// import { Home } from "../../components/Dashboard/Home/Home";
import Ocard from "../../components/Dashboard/orderCard/ocard";
import { getOrders, getSingleOrder } from "../../store/actions/orderAction";
import { CLEAR_ID, CLEAR_ORDER_ID } from "../../store/types";
// import { GOTO_RECALLPAGE } from "../../store/types";
// import StartCookModal from "../../components/Dashboard/Home/CookModal/startCook";
import { useLocation } from "react-router-dom";
import StepsModal from "../../components/Modals/stepsModal";
import SelectStation from "../../components/Modals/selectStation";
// import MultiOrders from "../../components/Dashboard/orderCard/multiorders";
import Dishes from "../../components/Dashboard/orderCard/dishes";

export default function HomeView({ }) {
  const dispatch = useDispatch();
  const location = useLocation();
  // const [cookingModalObj, setCookingModalObj] = useState({});
  // const [cookingModalOpen, setCookingModalOpen] = useState(false);
  const dataStore = useSelector((state) => state.orders.orders);
  const dishPriority = useSelector((state) => state?.orders?.dishPriority);
  const { processChange } = useSelector((state) => state?.orders);
  const removeItem = useSelector((state) => state?.orders?.removeItem);
  var selected_menu = location?.state?.menu;
  const { bumporder } = useSelector((state) => state.orders);
  const loading = useSelector((state) => state?.orders?.loading);
  const [ordersMenu, setOrderMenu] = useState([]);
  const [stationSlectModal, setStationSelect] = useState(false);
  const [orderObj, setOrderObj] = useState({});
  const [updateOrder, setUpdateOrder] = useState();
  const singleDataStore = useSelector((state) => state.orders?.singleOrder);
  const [data, setData] = useState(dataStore);
  const SingleLoading = useSelector((state) => state?.orders?.singleLoading);

  useEffect(() => {
    if (!dataStore.length) {
      dispatch(getOrders())
    }
  }, [])

  useEffect(() => {
    loadDta();
  }, [singleDataStore, dataStore]);

  useEffect(() => {
    if (
      dishPriority?.success ||
      processChange?.success ||
      removeItem.success ||
      bumporder.success
    ) {
      dispatch(
        getSingleOrder({
          _id: updateOrder?._id,
          businessLocationId: updateOrder?.businessLocationId,
        })
      );
    }
    dispatch({ type: CLEAR_ID });
    dispatch({ type: CLEAR_ORDER_ID });
  }, [dishPriority, processChange, removeItem, bumporder]);

  useEffect(() => {
    loadDta();
  }, [selected_menu, location.pathname]);

  function loadDta(type) {
    let tempData = [...dataStore];
    if (singleDataStore?._id !== undefined) {
      let orderIndex = tempData?.findIndex((item) => {
        return item?._id === updateOrder?._id;
      });

      if (orderIndex !== -1) {
        if (updateOrder?.bar && (tempData[orderIndex]?._id === singleDataStore?._id)) {
          //replace entire order to accomodate bar status
          tempData[orderIndex] = singleDataStore

        }
        else {
          let saleEntryIndex = tempData[orderIndex]?.salesEntries?.findIndex(
            (saleEntry) => {
              return saleEntry?._id === updateOrder?.dish_id;
            }
          );
          if (removeItem?.success) {
            //to remove sale entry
            let itemIndex = data[orderIndex]?.salesEntries.findIndex((item) => { return item._id === removeItem?.payload?.saleEntryID })
            if (itemIndex !== -1) {
              if (data[orderIndex]?.salesEntries.length === 1) {
                //remove entire order if deleted and only a single sale entry
                //TODO handle this on backend api
                data.splice(orderIndex, 1)
              } else {
                data[orderIndex].salesEntries.splice(itemIndex, 1)
              }
            }
            // }
          }
          else {
            let newDishIndex = singleDataStore?.salesEntries?.findIndex((x) => {
              return x._id === updateOrder?.dish_id;
            });

            if (
              newDishIndex !== -1 &&
              // singleDataStore !== -1 &&
              saleEntryIndex !== -1
            ) {
              // if (singleDataStore?.salesEntries[newDishIndex].status === "cooked") {
              //   //remove on cooked
              //   tempData[orderIndex].salesEntries.splice(saleEntryIndex, 1);
              // } else {
              //for updation
              tempData[orderIndex].salesEntries[saleEntryIndex] =
                singleDataStore?.salesEntries[newDishIndex];
              // }
            }
          }
        }
      }
    }

    let singleOrder = [];
    let se = tempData?.map((btx) => btx?.salesEntries);
    let flatArray = se?.flat();

    if (location?.state?.order === "cooked") {
      singleOrder = flatArray?.filter((itx) => {
        return (
          itx?.status === "cooked" ||
          itx?.payment_status === "half_paid" ||
          itx?.payment_status === "paid"
        );
      });
    } else {

      let not_cooked = flatArray?.filter((itx) => {
        return itx?.status === "pending" || itx?.status === "cooking"; //remove cooked item from view, will show cooked in RECALL view
      });

      singleOrder = not_cooked?.filter((itx) => {
        if (selected_menu !== "all")
          return itx?.station?.toLowerCase() === selected_menu?.toLowerCase();
        else return itx;
      });
    }

    setOrderMenu(singleOrder);
    setData(tempData);
  }

  if (ordersMenu?.length === 0 && loading) {
    return (
      <div className="position-relative min-vh-100 w-100">
        <Spinner className=" position-absolute top-50 start-50" />
      </div>
    )
  }
  else {
    return (
      <>
        {selected_menu === "bar" ? (
          <Dishes data={[...data]} setUpdateOrder={setUpdateOrder} updateOrder={updateOrder} SingleLoading={SingleLoading} />
        ) : (
          <Row className="mb-5">
            <Col md="12" lg="12" sm="12" xs="12">
              {ordersMenu?.length === 0 && !loading ? (
                <div className=" mt-5  text-muted d-flex align-items-center justify-content-center">
                  <p>Sorry this station have not relevant order or dish.</p>
                </div>
              ) : (
                <Slider {...employeeSettings} className="mx-5">
                  {ordersMenu?.map((si, index) => {
                    if (si !== undefined)
                      return (
                        <div>
                          <Ocard
                            dish={si}
                            data={data}
                            setStationSelect={setStationSelect}
                            setOrderObj={setOrderObj}
                            setUpdateOrder={(obj) => {
                              setUpdateOrder(obj);
                            }}
                            SingleLoading={
                              SingleLoading &&
                              updateOrder?.dish_id === si?._id
                            }
                          />
                        </div>
                      );
                  })}
                </Slider>
              )}
            </Col>
          </Row>
        )}
        <StepsModal />
        <SelectStation
          stationSlectModal={stationSlectModal}
          setStationSelect={setStationSelect}
          orderObj={orderObj}
        />
        {/* <StartCookModal
        startCooking={cookingModalOpen}
        cardId={cookingModalObj?.cardId}
        saleEntry={cookingModalObj.saleEntry}
        setStartCooking={setCookingModalOpen}
      /> */}
      </>
    );
  }

}
