import { SELECT_STATION } from "../Type/orderTypes";
import { FETCH_SESSIONS, FETCH_TABLES, SESSIONS_FAIL, SESSIONS_REQUEST, TABLE_CHANGE, TABLE_MERGE } from "../types";

const initialState = {
  sessions: [],
  loading: false,
  selectStation: [],
  tableChange: [],
  allTables:[],
  mergeTable:[]
};
export const tableSessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
      case FETCH_TABLES:
        return{
          ...state,
          loading:false,
          allTables:action.payload
        }
    case FETCH_SESSIONS:
      return {
        ...state,
        loading: false,
        sessions: action.payload,
      };
    case SELECT_STATION:
      return {
        ...state,
        loading: false,
        selectStation: action.payload,
      };
    case TABLE_CHANGE:
      return {
        ...state,
        loading: false,
        tableChange: action.payload
      }
      case TABLE_MERGE:
        return {
          ...state,
          loading: false,
          mergeTable: action.payload,
        };
    case SESSIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
