import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Col, Row } from "reactstrap";
import { employeeSettings } from "../../../assets/sliderSetting";
import MultiOrders from "./multiorders";

const Dishes = ({ data, setOrderObj, setUpdateOrder, updateOrder,setStationSelect }) => {
  const SingleLoading = useSelector((state) => state?.orders?.singleLoading);
  let barItems = data?.filter((orders) => {
    return orders.salesEntries?.some((dish) => {
      return dish.station === "bar" && (dish?.status === "cooking" || dish?.status === "pending");
    });
  });
  return (
    <>
      <Row className="mb-5">
        <Col md="12" lg="12" sm="12" xs="12">
          <Slider {...employeeSettings} className="mx-5">
            {barItems?.map((order) => {
              if (order?.salesEntries.length) {
                return (
                  <div>
                    {" "}
                    <MultiOrders
                      order={order}
                      setUpdateOrder={(obj) => {
                        setUpdateOrder(obj);
                      }}
                      SingleLoading={
                        SingleLoading === true &&
                        updateOrder?._id === order?._id
                      }
                    />
                  </div>
                );
              }
              else {
                return (
                  <div>
                    <h4 className="text-mute">There is no more items to cook...</h4>
                  </div>
                )
              }
            })}
          </Slider>
        </Col>
      </Row>
    </>
  );
};

export default Dishes;
