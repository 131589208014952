export const ORDER_REQUEST='ORDER_REQUEST';
export const SINGLE_ORDER_REQUEST='SINGLE_ORDER_REQUEST';
export const FETCH_ORDER='ORDER_FETCH';
export const FETCH_SINGLE_ORDER="FETCH_SINGLE_ORDER"
export const ORDER_FAIL="ORDER_FAIL";
export const ORDER_UPDATE="ORDER_UPDATE";
export const ORDER_PROCESS_COOKING = "ORDER_PROCESS_COOKING";
export const ORDER_PRIORITY_CHANGE = "ORDER_PRIORITY_CHANGE";
export const ORDER_CANCEL= "ORDER_CANCEL";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const FETCH_SESSION_ORDER="FETCH_SESSION_ORDER";
export const DISH_PRIORITY = "DISH_PRIORITY";
export const BUMP_ORDER ="BUMP_ORDER";
export const SELECT_STATION="SELECT_STATION";
export const BAR_LOADER="BAR_LOADER";
//===============COURSES Types================///
export const GET_COURSES = "GET_COURSES";
export const REMOVE_SINGLE_ORDER='REMOVE_SINGLE_ORDER'