import {
  FETCH_ORDER,
  ORDER_FAIL,
  ORDER_REQUEST,
  ORDER_UPDATE,
  ORDER_PROCESS_COOKING,
  ORDER_PRIORITY_CHANGE,
  ORDER_CANCEL,
  REMOVE_ITEM,
  FETCH_SESSION_ORDER,
  DISH_PRIORITY,
  FETCH_SINGLE_ORDER,
  SINGLE_ORDER_REQUEST,
  BUMP_ORDER,
  GET_COURSES,
  SELECT_STATION,
  BAR_LOADER,
  REMOVE_SINGLE_ORDER
} from "../Type/orderTypes";
import { CHANGE_THE_STATION } from "../Type/stationTypes";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import OrderRepository from "../../repository/OrderRepository";
import { socket } from "../../services/services.sockets";

var orderRepository = RepositoryFactory.get("order");
export const getOrders = (payload) => async (dispatch) => {
 
  try {
    dispatch({ type: ORDER_REQUEST });
    const res = await orderRepository.getOrders();
    dispatch({
      type: FETCH_ORDER,
      payload: res?.data?.data,
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: FETCH_ORDER,
      payload: [],
    });
    dispatch({
      type: ORDER_FAIL,
      payload: error,
    });
  }
};

export const getSingleOrder = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_ORDER_REQUEST });
    const res = await orderRepository.getSingleOrder(payload);
    dispatch({
      type: FETCH_SINGLE_ORDER,
      payload: res?.data?.data,
    });
    // console.log('single order',res?.data?.data)
  } catch (error) {
    console.log("error", error?.response?.data?.id);
    // dispatch({
    //   type: REMOVE_SINGLE_ORDER,
    //   payload: error?.response?.data?.id,
    // });
    dispatch({
      type: ORDER_FAIL,
      payload: error,
    });
  }
};
// Order Completed
export const UpdateOrder = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: ORDER_REQUEST });
    const res = await orderRepository.updateOrder(payload);
    dispatch({
      type: ORDER_UPDATE,
      payload: res?.data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_FAIL,
      payload: error,
    });
  }
};
//Change Station
export const changeOrderStAction = (payload) => async (dispatch) => {
  try {
    const res = await orderRepository.chanageTheSt(payload);
    dispatch({
      type: CHANGE_THE_STATION,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ORDER_FAIL,
      payload: error,
    });
  }
};
//Start or Cancel cooking
export const ProcessCookingAction = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: ORDER_REQUEST });
    const res = await orderRepository.chanageCookingProcess(payload);
    // console.log("ck res->", res);
    // socket.emit('recipiesCooked',{oredr:'Order ha yah'})
    if (res.data.message == "This order has been Cooked") {
      socket.emit("recipiesCooked", { sessionId: res.data.sessionId });
    }
    dispatch({ type: ORDER_PROCESS_COOKING, payload: res.data });
  } catch (error) {
    dispatch({ type: ORDER_FAIL, payload: error });
  }
};

//Change Order Priority

export const orderPriorityAction = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: ORDER_REQUEST });
    const res = await orderRepository.ordrPriorityRepo(payload);
    dispatch({ type: ORDER_PRIORITY_CHANGE, payload: res.data });
  } catch (error) {
    dispatch({ type: ORDER_FAIL, payload: error });
  }
};

//DISH PRIORITY
export const dishPrioirtyAction = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: ORDER_REQUEST });
    const res = await orderRepository.dishPriority(payload);
    dispatch({ type: DISH_PRIORITY, payload: res.data });
  } catch (error) {
    dispatch({ type: ORDER_FAIL, payload: error });
  }
};

//Order Remove
export const orderCancelAction = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: ORDER_REQUEST });
    const res = await OrderRepository.cancelOrderRepo(payload.order_id);
    const response = { ...res.data, orderID: payload.order_id }; //need to send back deleted order id and sale entry id to handle

    dispatch({ type: ORDER_CANCEL, payload: response });
  } catch (error) {
    alert(error?.response?.data?.message);

    dispatch({ type: ORDER_FAIL, payload: error });
  }
};

//Remove Item
export const removeItemAction = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: ORDER_REQUEST });
    const res = await OrderRepository.removeItemRepo(payload);
    const response = { ...res.data, payload }; //need to send back deleted order id and sale entry id to handle

    // dispatch({ type: REMOVE_ITEM, payload: res.data });
    dispatch({ type: REMOVE_ITEM, payload: response });
  } catch (error) {
    alert(error?.response?.data?.message);
    dispatch({ type: ORDER_FAIL, payload: error });
  }
};

//Get orders by Session/table
export const getOrdersBySession = (payload) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_REQUEST });
    const res = await orderRepository.getOrdersBySession(payload);
    if (res?.data?.success) {
      dispatch({
        type: FETCH_SESSION_ORDER,
        payload: res?.data?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_SESSION_ORDER,
      payload: [],
    });
    dispatch({
      type: ORDER_FAIL,
      payload: error,
    });
    console.log("error", error);
  }
};

//BUMP ORDERS

export const bumpOrderAction = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: ORDER_REQUEST });
    let { data } = await OrderRepository.bumpOrder(payload);
    dispatch({
      type: BUMP_ORDER,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({ type: ORDER_FAIL, payload: error });
  }
};

export const getCourses = () => async (dispatch) => {
  try {
    let { data } = await OrderRepository.getCourses();
    dispatch({
      type: GET_COURSES,
      payload: data.data,
    });
  } catch (err) {
    alert("Something went wrong try again" + err);
  }
};

export const toggleBarLoader = (payload) => async (dispatch) => {
  try {
    console.log("bar loader paylaod", payload);
    dispatch({ type: BAR_LOADER, payload: payload });
  } catch (err) {
    alert("Failed to set bar loading");
  }
};
