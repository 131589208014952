
import {
 RECIPE_FETCH,
 RECIPE_FAILL,
 RECIPE_REQUEST,
 RECIPE_SINGLE_FETCH,
 RECIPE_RESET
  } from "../types";
  
  const initialState = {
    recipes: [],
    singleReceipe:[],
    loading:false
  };
  
  export const recipeReducer = (state = initialState, action) => {
    switch (action.type) {
      case RECIPE_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case RECIPE_FETCH:
        return {
          ...state,
          loading: false,
          recipes: action.payload,
        };
        case RECIPE_SINGLE_FETCH:
          return {
            ...state,
            loading: false,
            singleReceipe: action.payload,
          };

      case RECIPE_FAILL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
        case RECIPE_RESET:
        return {
          ...state,
          loading: true,
          recipes:[],
        };
      default:
        return state;
    }
  };
  