import { useState } from "react";
import React from "react-dom";
import { STEPS_MODAL_OPEN } from "../../../store/types";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const PreprationCard = ({ cardno, steps, ingredients }) => {
  const [showPr, SetshowPr] = useState(-1);
  const location = useLocation();

  const dispatch = useDispatch();
  const svgMore = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="white"
      className="bi bi-caret-down-fill"
      viewBox="0 0 16 16"
    >
      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
    </svg>
  );
  const svgLess = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="white"
      className="bi bi-caret-up-fill"
      viewBox="0 0 16 16"
    >
      <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
    </svg>
  );
  return (
    <>
      {" "}
      {location.pathname.includes("employee") && (
        <div className="orderitem">
          <div
            className="itemheaderPr bgitemheader_rock"
            onClick={() => {
              showPr === cardno ? SetshowPr(-1) : SetshowPr(cardno);
            }}
          >
            <span className="text-white">PREPARATION</span>
            <span className="oi_pow">
              {showPr === cardno ? svgLess : svgMore}
            </span>
          </div>
          {showPr === cardno ? (
            <>
              <div
                className="p-3"
                style={{
                  position: "relative",
                  backgroundColor: "#7F8C8D",
                  width: "100%",
                }}
              >
                {ingredients.length > 0 ? (
                  ingredients?.map((ing, index) => {
                    return (
                      <ul>
                        <li>
                          {" "}
                          <b>{index + 1}-</b> {ing?.name}
                        </li>
                      </ul>
                    );
                  })
                ) : (
                  <span>No Steps Present</span>
                )}
                <div
                  className="footStep-btn"
                  onClick={() => {
                    dispatch({ type: STEPS_MODAL_OPEN, payload: steps });
                  }}
                >
                  <img
                    src="/img/icons/steps.png"
                    height={25}
                    className="mt-1"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default PreprationCard;
