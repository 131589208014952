import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Spinner } from "reactstrap";
import { saveServiceNotifications } from "../../store/actions/servicesAction";
import ModalInterface from "../ModalInterface";

const ServiceNotification = ({ notifyModal, setNotifyModal, notifyMsg, blID }) => {
    const loading = useSelector((state) => state?.services?.loading)

    const dispatch = useDispatch();
    const saveNotfication = (notifyData) => {
        dispatch(saveServiceNotifications({
            "blID": blID,
            "sessionID": notifyData?.sessionID,
            "table": notifyData?.tableNo,
            "message": notifyData?.message
        })).then(() => {
            setNotifyModal(false);
        })

    }

    return (
        <>
            <ModalInterface
                display={notifyModal}
                ceneter={true}
                size={"md"}

                content={

                    loading ? <div className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" />
                    </div> :
                        <>
                            <Row>
                                <Col lg="12" md="12" sm="12" xs="12">

                                    <div className="msg-wrapper p-3">
                                        <p>{`${notifyMsg?.message} on Table ${notifyMsg?.tableNo
                                            } with Session ID ${notifyMsg?.sessionID?.substr(
                                                notifyMsg?.sessionID.length - 4
                                            )} kindly check that out`}</p>
                                        <div className="mt-4 d-flex justify-content-between">
                                            <Button outline color="danger" onClick={() => { setNotifyModal(false) }}>Dismiss</Button>
                                            <Button outline color="success" onClick={() => { saveNotfication(notifyMsg) }}>Save</Button>{' '}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                }
                closeEvent={() => {
                    setNotifyModal(false);
                }}
            />
        </>
    );
};

export default ServiceNotification;
