// import Categories from '../../components/RecipeList/Categories';
import List from "../../components/Employee/RecipeList/List";
// import { Sidebar } from '../components/RecipeList/Sidebar';
import "../../assets/css/recipe.css";
import {
  Button,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import ModalInterface from "../../components/ModalInterface";
import { STEPS_MODAL_OPEN } from '../../store/types'
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRecipeAction, refreshRecipes } from "../../store/actions/recipeAction";
import StepsModal from "../../components/Modals/stepsModal";


const RecipeList = () => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state?.authUser?.user?.data)
  const loader = useSelector(state => state?.recipes?.loading)
  const ingredients = useSelector(state => state?.recipes?.singleReceipe?.ingredients)
  const singleRecipe = useSelector(state => state?.recipes?.singleReceipe)
  const recipesData = useSelector(state => state?.recipes.recipes);
  const stepdata = useSelector(state => state?.recipes?.singleReceipe?.steps);
  const [activeGroup, setActiveGroup] = useState('')
  const [activeRecipe, setActiveRecipe] = useState('')

  useEffect(() => {
    dispatch(getRecipeAction());
  }, [])

  useEffect(() => {
    // if (recipesData.length > 0) {
    setActiveGroup(userData?.stations[0])
    setFirstRecipe(userData?.stations[0])
    // }
  }, [recipesData.length])


  const setFirstRecipe = (group) => {
    const defaultGroup = recipesData.filter((item) => {
      return item.group_name.toLowerCase() === group
      // dispatch(getSingleRecipe(recipesData[0]?.recipe_id))
    })
    // setActiveRecipe()
    setActiveRecipe({ id: defaultGroup[0]?.recipe_id, name: defaultGroup[0]?.recipe_name })
  }
  return (
    <>
      {recipesData.length > 0 ?
        <div className="container-fluid mb-5">
          <div className="row flex-nowrap">
            <div className="col-auto px-0 ">
              <div
                id="sidebar"
                className="collapse collapse-horizontal show border-end"
              >
                <div
                  id="sidebar-nav"
                  className=" recipes-name mt-5 pt-5  list-group border-0 rounded-0 text-sm-start"
                >
                  {userData?.stations?.map((group) => {
                    return (
                      <Link
                        onClick={() => {
                          setActiveGroup(group)
                          setFirstRecipe(group)
                          // setActiveRecipe('')
                        }}
                        to={`/employee/dashboard/recipe-list/${group}`}
                        className=" list-group-item border-end-0 d-inline-block text-truncate p-4 recipe-list-btns "
                      >
                        <h5>{group?.toUpperCase()}</h5>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="recipe-group col-auto px-0 mt-2"

            >
              <div
                id="sidebar collapseExample"
                className="collapse collapse-horizontal show border-end"
              >
                <div
                  id="sidebar-nav"
                  className="list-group border-0 rounded-0 text-sm-start text-center"
                >
                  <List recipesData={recipesData.filter((item) => {
                    return item.group_name.toLowerCase() === activeGroup
                  })} setActiveRecipe={setActiveRecipe} />
                </div>
              </div>
            </div>

            <main className="col ps-md-2 p-3 mt-2">
              <div style={{ textAlign: "right" }}>
                <Button onClick={() => {
                  dispatch(refreshRecipes());
                }}>
                  Refresh
                </Button>
              </div>
              <div className="row container">
                <h3>{activeRecipe?.name}</h3>
                <div className="col-12">
                  <div className="bg-light p-5 m-2">

                    <img
                      // src="#"
                      src={singleRecipe?.main_image?.full_image_url}
                      width={600}
                      height={300}
                      alt=""
                      className={activeRecipe?.name !== singleRecipe?.name || singleRecipe?.main_image?.full_image_url === undefined ? "invisible" : ''}
                    />
                  </div>
                  <Row>
                    <Col xs="12" sm="12" md="6" lg="6">
                      <h3 className="d-inline col-6">INGREDIENTS</h3>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="6">
                      <Button
                        size="md"
                        className="stpbstp d-inline pl-3 pr-3 ms-auto mb-2 d-flex justify-content-end"
                        onClick={() => {
                          if (stepdata.length > 0) {
                            // setDisplay(true);
                            dispatch({
                              type: STEPS_MODAL_OPEN,
                              payload: stepdata
                            })
                          }
                          else {
                            alert("you have not selected any recipe")
                          }
                        }}
                      >
                        <span className="p-1"> STEP BY STEP</span>{" "}
                        <img
                          src="/img/icons/steps.png"
                          height={25}
                          className="mt-1"
                          alt="step by step instructions"
                        />
                      </Button>
                    </Col>
                  </Row>
                  {loader ? <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                    : <div>
                      {/* {activeRecipe !== '' && */}
                      {ingredients?.map((item) => {
                        return <h6> - {item?.name}</h6>;
                      })}
                    </div>
                  }
                  <h3 className="mt-5">PREPARATION</h3>
                </div>
              </div>
            </main>
          </div>
        </div>
        : <div className="d-flex position-absolute top-50 start-50">
          <Spinner />
        </div>
      }
      <StepsModal />

    </>
  );
};

export default RecipeList;
