import { STATION_FAIL,STATION_REQUEST, FETCH_STATIONS } from "../Type/stationTypes";

const initialState = {
	stations: [],
	loading: false,
};

export const stationReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATION_REQUEST:
            return {
                ...state,
                loading: true,
                isAuthenticated: false
            }

        case FETCH_STATIONS:
            return {
                ...state,
                loading: false,
                stations: action.payload
            }

        case STATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
 
        default:
            return state
    }
}

