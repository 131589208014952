import StationRepository from "./StationRepository";
import OrderRepository from "./OrderRepository";
import AuthRepository from "./AuthRepository";
import ReceipeRepository from "./ReceipeRepository";
import TableRepository from "./TableRepository";
import ServicesRepository from "./ServicesRepository";

const repositories={
    station:StationRepository,
    order:OrderRepository,
    auth:AuthRepository,
    recipe:ReceipeRepository,
    table: TableRepository,
    service:ServicesRepository
}
export const RepositoryFactory = {
	get: (name) => repositories[name],
};
