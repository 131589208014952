import { useEffect, useState } from "react";
import PreprationCard from "../../Dashboard/PreprationCard/Prepration";
import {
  GET_ID,
  ORDER_ID,
  CLEAR_ORDER_ID,
  CLEAR_ID,
} from "../../../store/types";
import { useLocation } from "react-router-dom";
import CookModal from "./CookModal/cookmodal";
// import StartCookModal from "./CookModal/startCook";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { bumpOrderAction, ProcessCookingAction } from "../../../store/actions/orderAction";
import { changeBumpStatus } from "../../../store/actions/tableSessionsAction";
// import { getSingleOrder } from "../../../store/actions/orderAction";
export const Cards = (props) => {
  const orderCreation = new Date(props.createdAt);
  const cookingStarted =
    props.cookingStarted !== false ? new Date(props.cookingStarted) : false;
  const dispatch = useDispatch();
  const ordrIDStore = useSelector((state) => state?.info?.oId?.Id);
  const [cardData, setCardData] = useState([]);
  // const [saleEntry, setSaleEntry] = useState("");
  const [cook, setCook] = useState(false);
  const [current, setCurrent] = useState(new Date());
  // const [startCooking, setStartCooking] = useState(false);
  const [allCooked, setAllCooked] = useState(false);
  const [cookingStatus, SetCookingStatus] = useState("");
  const idStore = useSelector((store) => store?.info?.id);
  const courses = useSelector((state) => state.orders.courses);
  const location = useLocation();

  useEffect(() => {
    if (props && courses.length) {
      let coursesarray = [...courses];
      let swipe = coursesarray[coursesarray.length - 1];
      coursesarray[coursesarray.length - 1] =
        coursesarray[coursesarray.length - 2];
      coursesarray[coursesarray.length - 2] = swipe;
      let result = [];
      coursesarray?.map((item, index) => {
        result = result.concat(
          props?.orders?.filter((itm) => itm.course === item.title)
        );
      });
      setCardData(result);
    }
  }, [props, courses]);

  const checkCourse = (item, array, index) => {
    for (let i = 0; i < index; i++) {
      if (item.course == array[i].course) {
        return true;
      }
    }
  };


  useEffect(() => {
    const allCooked = props?.item?.salesEntries.every((x) => {
      return x?.status === "cooked";
    });
    // const paid = props?.item?.salesEntries.every((x) => {
    //   return x?.payment_status  === "paid" || x?.payment_status === "half_paid";
    // });

    const cookingOne = props?.item?.salesEntries.some((x) => {
      return x?.status === "cooking";
    });
    const pendingsome = props?.item?.salesEntries.some((x) => {
      return x?.status === "pending";
    });
    const allPending = props?.item?.salesEntries.every((x) => {
      return x?.status === "pending";
    });
    if (allCooked === true) {
      SetCookingStatus("bgplaygreen");
    } else if (cookingOne) {
      SetCookingStatus("bgorder-yellow");
    } else if (allPending) {
      SetCookingStatus("bgOrder-light-Gray");
    } 
    
    // else if (paid) {
    //   SetCookingStatus("bgplaygreen");
    // }
    else if (pendingsome) {
      SetCookingStatus("bgOrder-light-Gray");
    }

  }, [props?.item]);
  //Check Status is Cooking Or Cooked or Pending
  useEffect(() => {
    const allCooked = props?.item?.salesEntries.every((x) => {
      return x?.status === "cooked";
    });
    if (allCooked) {
      setAllCooked(true);
    }
  }, [props?.item]);

  function refreshTime() {
    if (!allCooked) {
      setCurrent(new Date());
    }
  }
  // setTimeout(refreshTime, 1000);

  let timerToCook = 0;

  // const TimeAdd = cardData.map((cardD, index) => {
  //   return timerToCook = timerToCook + cardD.preperation_time
  // })

  //   useEffect(()=>{
  //     msConverter(0 - 0)
  //   }
  // },[])

  function msConverter(diff) {
    if (diff < 0) {
      //in some cases cooking time out of sync so adjust time
      diff += 50000; //30sec time adjustment
    }
    var msec = Math.abs(diff);
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }
    return hh + ":" + mm + ":" + ss;
  }
  //Prioirty Order
  const priorityOrder = (id) => {
    dispatch({
      type: ORDER_ID,
      payload: {
        Id: id,
        priority: props?.item?.priority,
      },
    });
    dispatch({ type: CLEAR_ID });
  };
  const StationItemChange = (id, item) => {
    if (item?.payment_status === "paid" || item?.payment_status === "half_paid") {
      alert("This item has been paid");
    } else {
      dispatch({
        type: GET_ID,
        payload: { Id: id, stationName: item },
      });
      dispatch({ type: CLEAR_ORDER_ID });
    }
  };

  const handleClick = (e) => {
    let cardTags = document.getElementsByClassName("order");
    let outsideClicked = true;
    for (let cardTag of Array.from(cardTags)) {
      if (cardTag.contains(e.target)) {
        outsideClicked = false;
        break;
      }
    }
    if (outsideClicked) {
      dispatch({ type: CLEAR_ORDER_ID });
      props?.setSaleEntrySelected("");
    }
  };
  //==============Start cooking process=============
  const cookingFunc = (dish) => {
    let status = dish?.status;
    if (status === "cooked" || dish?.payment_status === "half_paid") {
      alert("This dish has been prepared.");
    }
    else if (status === "pending") {
      dispatch(
        ProcessCookingAction({
          order_id: props?.item?._id,
          item_id: dish?._id,
          status: "cooking",
        })
      );
      props?.setUpdateOrder();
      props.setSaleEntrySelected(dish?._id)
    }
    else if (status === "cooking") {
      dispatch(
        ProcessCookingAction({
          order_id: props?.item?._id,
          item_id: dish?._id,
          status: "cooked",
        })
      )
      dispatch(
        bumpOrderAction({
          order_id: props?.item?._id,
          bumped: true,
          item_id: dish?._id,
        })
      )
        dispatch(
          changeBumpStatus({
            id:props?.item?.sessionId,
            status: true,  
          })
           )
     
      props?.setUpdateOrder();
      props.setSaleEntrySelected(dish?._id)
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <>
      <div className="card-tag">
        {/* {location.pathname.includes("admin") ? (
          ""
        ) : (
          <div
            className={`plateTag ${cookingStatus}  d-flex justify-content-center align-items-center`}
            onClick={() => {
              setStartCooking(true);
              // props?.setUpdateOrder()
              setSaleEntry(idStore?.stationName);
            }}
          >
            <img src="/img/icons/BUMP_WHITE.png" height={60} alt="dishLogo" />
          </div>
        )} */}
        <div
          className={
            ordrIDStore === props?.cardId
              ? "order pb-5 mb-5  order-selected"
              : "order pb-5 mb-5"
          }
        >
          <div
            className={
              ordrIDStore === props.cardId ? "card-tag-overlay" : "abc"
            }
          ></div>

          <div className="orderTop">
            <div
              className="ordTp bgorder-darkblue"
              onClick={() => {
                if (location.pathname.includes("admin")) {
                  priorityOrder(props?.cardId);
                  props?.setSaleEntrySelected("");
                  props?.setUpdateOrder();
                }
              }}
            >
              <span>
                <img src="/img/icons/BILL_WHITE.png" alt="logo" />
              </span>
              <span className="oT_no">
                {props?.cardId?.substr(props?.cardId.length - 4)}
              </span>
            </div>
            <div className="ordTp bgorder-blue">
              <span>
                <img src="/img/icons/TABLE_WHITE.png" alt="logo" />
              </span>
              <span className="oT_no">{props?.orderTable}</span>
            </div>
            {props?.ordrType === "served" ?
              <div className="ordTp bgorder-yellow">
                <span>
                  <img src="/img/icons/INSTORE_WHITE.png" alt="logo" />
                </span>
                <span className="oT_no">I</span>
              </div>
              :
              <div className="ordTp bgorder-pink ">
                <span>
                  <img src="/img/icons/INSTORE_WHITE.png" alt="logo" />
                </span>
                <span className="oT_no">T</span>
              </div>
            }
          </div>
          {props?.ordrType !== undefined ? (
            <div className="taketime bgorder-darkpink">
              <span>{props?.ordrType}</span>
              <span>{props?.item?.sessionName}</span>
            </div>
          ) : (
            ""
          )}
          {props?.item?.priority === "urgent" && (
            <div className="taketime priority ">
              <span>Priority</span>
              <span>
                <img src="/img/icons/ALERT_WHITE.png" alt="logo" />
              </span>
            </div>
          )}
          <div
            className={
              "orderItem_handler pb-3" +
              (props?.item?.priority === "urgent" && props?.ordrType !== null
                ? " orderItem_height_takeway"
                : props?.item?.priority === "urgent" ||
                  props?.ordrType !== undefined
                  ? " orderItem_priority_handler"
                  : " orderItem_height_handler")
            }
          >
            {cardData.map((item, index) => {
              timerToCook = timerToCook + item.preperation_time;
              let singleItemReload =
                location.pathname.includes("admin") ||
                item?._id === props?.saleEntrySelected;

              return (
                <>
                  {props?.loading && singleItemReload ? (
                    <div
                      className="d-flex position-absolute"
                      style={{ left: "45%", top: "45%" }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <div className="orderitem">
                        <div
                          className={
                            idStore?.stationName?._id === item?._id &&
                              !props?.clearSelection
                              ? "item-overlay"
                              : "abc"
                          }
                        ></div>
                        <div className="course">
                          <span>
                            {checkCourse(item, cardData, index)
                              ? null
                              : <div><p style={{ padding: '8px', fontWeight: 'bold', margin: 0 }}>{item?.course?.toUpperCase()}</p></div>
                            }
                          </span>
                        </div>
                        <div
                          className={
                            item?.priority === "urgent"
                              ? `itemheader bgitemheader_hotpink`
                              : "itemheader"
                          }
                        >
                          <span>
                            <b>{item?.quantity}x </b>
                            <b
                              className={
                                props?.saleEntrySelected === item?._id &&
                                  !props?.clearSelection
                                  ? "itemheader_name_selected "
                                  : "itemheader_name"
                              }
                              onClick={() => {
                                // props?.setDisplay(true);
                                props.SetStationItem(item);
                                // props.setCardID(props.cardId);
                                // priorityOrder(props?.cardId);
                                props?.setSaleEntrySelected(item?._id);
                                StationItemChange(props?.cardId, item);
                                props?.setUpdateOrder();
                              }}
                            >
                              {item._id ? item?.itemName?.toUpperCase() : "N/A"}
                            </b>
                          </span>
                          <span className={item.status === "cooked" || item?.payment_status === "half_paid" || item?.payment_status === "paid" ? 'oi_pow bgplaygreen' : item?.status === "cooking" ? "oi_pow bgorder-yellow" : "oi_pow bgOrder-light-Gray"}>
                            {location.pathname.includes("employee") ? (
                              <div
                                className={`playbtn bgplay${props.playBgColor}`}
                                onClick={() => {
                                  cookingFunc(item);
                                }}
                              >
                                <img
                                  src="/img/icons/play.png"
                                  height={30}
                                  alt="playicon"
                                />
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  cookingFunc(item)
                                }}
                              >
                                {/* <span>{item?.station?.toUpperCase()}</span>  */}
                                {item?.station?.toUpperCase()}
                              </div>
                            )}
                          </span>
                        </div>

                        <div className={`itembody`}>
                          {/* <div className="course">
                            <span>{item?.course?.toUpperCase()}</span>
                          </div> */}

                          {item?.subLineItems.map((sl, index) => {
                            return (
                              <>
                                {sl.type === "Standard" &&
                                  sl.isSingleOnly === true ? (
                                  <div className="titleitem mt-2">
                                    <span>
                                      <img
                                        src="/img/icons/MIDARROWRIGHT_BLACK.png"
                                        alt="logo"
                                      />
                                    </span>
                                    <span>{sl?.itemName}</span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                          {item?.redItems?.length > 0 || item?.greenItems?.length > 0 ?
                            <div className="two-card-sec d-flex justify-content-between mt-3 ">
                              <div class="block-card bg-pink">
                                <span>
                                  {" "}
                                  <img
                                    src="/img/icons/MINUS_BLACK.png"
                                    alt="minus"
                                    height="12"
                                  />
                                </span>
                                {item?.redItems?.map((gi, index) => {
                                  return (
                                    <div className="f-12">
                                      <span>{gi?.itemName}</span>
                                    </div>
                                  );
                                })}
                              </div>
                              <div class="block-card bg-lightGreen">
                                <span>
                                  {" "}
                                  <img
                                    src="/img/icons/PLUS_BLACK.png"
                                    alt="minus"
                                    height="12"
                                  />
                                </span>
                                {item?.greenItems?.map((ri, index) => {
                                  return (
                                    <div className="f-12">
                                      <span>{ri?.itemName}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div> : ""
                          }
                          {/* Extras  */}
                          {/* <div className="itemMinuPlus mx-2 d-flex flex-column">
                            {item?.subLineItems?.map((sli, index) => {
                              return (
                                <>
                                  {sli.type === "Extras" ||
                                    sli?.type === "normal" ? (
                                    <div className="itemminus mt-2">
                                      <span>{sli?.itemName}</span>
                                    </div>
                                  ) : (
                                    // <div className="itemminus itemquant d-flex flex-row">
                                    //   {/* <span>
                                    //     <img
                                    //       src="/img/icons/MIDARROWRIGHT_BLACK.png"
                                    //       alt="logo"
                                    //     />
                                    //   </span> */}

                          {/* //   <ul>
                                    //     <li>{sli?.itemName}</li>
                                    //   </ul>
                                    // </div>
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </div> */}

                          {/* for minus items and add items */}
                          {/* <div className="itemMinuPlus">
                          <div className="itemminus itemquant d-flex">
                            <img
                              src="/img/icons/MINUS_BLACK.png"
                              alt="logo"
                              className="mt-1"
                            />

                            <span>ONIONS</span>
                          </div>
                          <div className="itemplus itemquant d-flex">
                            <div>
                              <img
                                src="/img/icons/PLUS_BLACK.png"
                                alt="logo"
                                className="mt-2"
                              />
                            </div>
                            <ul>
                              <li>SALAD</li>
                              <li>MAYO</li>
                              <li>B. VYNEGAR</li>
                            </ul>
                          </div>
                        </div> */}
                        </div>
                      </div>
                      {props?.selected_menu === "all" && (
                        <PreprationCard
                          cardno={item?._id}
                          steps={item?.steps}
                          ingredients={item?.ingredients}
                        />
                      )}
                    </>
                  )}
                </>
              );
            })}
          </div>

          {/* // Footer SEction  Timer // */}
          <div className="orderFooter ">
            <div className="ordTp bgOrder-Gray">
              <span className="">
                <img src="/img/icons/TIMER_WHITE.png" alt="logo" />
              </span>
              <span className="bgof-green time-txt">
                {/* {timeConvert(TimeAdd)} */}
                {msConverter(current - orderCreation)}
              </span>
            </div>
            <div className={`ordTp ${cookingStatus}`}>
              <span>
                <img src="/img/icons/READY_WHITE.png" alt="logo" />
              </span>
              {/* <span className="bgof-green text-white"> */}
              <span className="time-txt">
                {props?.cookingStarted === false
                  ? // <CountDown />
                  "00:00:00"
                  : msConverter(current - cookingStarted)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* // Cook Modal */}

      <CookModal
        cook={cook}
        setCook={setCook}
        cardId={props?.cardId}
        setUpdateOrder={() => {
          props?.setUpdateOrder();
        }}
      />
      {/* <StartCookModal
        startCooking={startCooking}
        setStartCooking={setStartCooking}
        cardId={props?.cardId}
        saleEntry={saleEntry}
        setUpdateOrder={() => {
          props?.setUpdateOrder();
        }}
      /> */}
    </>
  );
};
