import { FETCH_STATIONS, STATION_FAIL } from "../Type/stationTypes";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var stationRepository = RepositoryFactory.get("station");

export const getAllStations = (payload) => async (dispatch) => {
  try {
    const res = await stationRepository.getStation();
    dispatch({
      type: FETCH_STATIONS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: STATION_FAIL,
      payload: error,
    });
  }
};
