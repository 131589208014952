import { LOGIN, LOGOUT, LOADING, FAIL_ACCESS,GET_BUSINESSID, SET_BUSINESS } from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var authRepository = RepositoryFactory.get("auth");

export const login = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LOADING });
    let { data } = await authRepository.getAuth(payload);
    dispatch({
      type: LOGIN,
      payload: data,
    });
    return {
      type: LOGIN,
      payload: data,
    };
  } catch (err) {
    dispatch({ type: FAIL_ACCESS });
    console.log("error", err);
    alert(err.response.data.message);
    return {
      type: FAIL_ACCESS,
      message: err.response.data.message,
    };
  }
};

export const logout = () => async (dispatch) => {
  localStorage.clear();
  dispatch({
    type: LOGOUT,
  });
};

export const getBusinessId =()=> async(dispatch)=>{
try{
  // dispatch({ type: LOADING });
  let {data} = await authRepository.getBusinessId();
return{
  type: GET_BUSINESSID,
    payload: data,
}
}
catch(err){
  dispatch({
    type:FAIL_ACCESS,
    payload:err
  })
}
};

export const setBusinesse = (data) => async dispatch => {
	try {
		localStorage.setItem("blId", data);
		dispatch({
			type: SET_BUSINESS,
			payload: data,
		});

	} catch (e) {
		console.log(e)
	}
};