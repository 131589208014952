import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Col, Row } from "reactstrap";
import BottomNav from "../../components/Dashboard/BottomNav";
import TopNav from "../../components/Dashboard/TopNav";
import HomeView from "./HomeView";
export default function EmployeeDashboard() {
  const [active, setActive] = useState(-1);
  return (
    <div>
      <TopNav active={active} setActive={setActive} />
      <div className=" mb-5 pb-5 pt-5">
        <Outlet />
      </div>
      <div className="mt-5">
        <BottomNav active={active} setActive={setActive} />
      </div>
    </div>
//     <>
//     <Row>
//   <Col md='12' lg="12" sm="12" xs="12">
//    <HomeView/>
//   </Col>
// </Row></>
  );
}
