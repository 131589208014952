 import { GET_ID,CLEAR_ID,ORDER_ID,CLEAR_ORDER_ID } from "../types";
const initialState={
    id:[],
    oId:[]
}

export const IdReducer =(state= initialState, action)=>{
    switch (action.type) {
        case GET_ID:
          return {
            ...state,
            id: action.payload,
            oId:""
          }
          case ORDER_ID:
            return {
              ...state,
              oId: action.payload,
              id:""
            }
          case CLEAR_ID:
            return{
              ...state,
              id:""
            }
            case CLEAR_ORDER_ID:
              return{
                ...state,
                oId:""
              }
          default:
            return state;
        }   
}