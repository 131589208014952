import React from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import {
  CLEAR_ID,
  CLEAR_ORDER_ID,
  GET_ID,
  ORDER_ID,
} from "../../../store/types";

const OcardHeader = ({
  dish,
  tableNo,
  order,
  setUpdateOrder,
  setSingleOrder,
}) => {
  const dispatch = useDispatch();
  const ItemIdSelect = (dish) => {
    if (dish?.payment_status === "paid" || dish?.payment_status === "half_paid") {
      alert("This item has been paid");
    } else {
      setUpdateOrder({
        _id: order?._id,
        dish_id: dish?._id,
        businessLocationId: order?.businessLocationId,
      });
      dispatch({
        type: GET_ID,
        payload: { Id: order?._id, stationName: dish },
      });

      dispatch({ type: CLEAR_ORDER_ID });
    }
  };
  return (
    <>
      <div className=" d-flex justify-content-between card-header">
        <div
          className="d-flex bg-darkblue header-col"
          onClick={() => {
            ItemIdSelect(dish);
            // setSingleOrder(order);
          }}
        >
          <img src="/img/icons/BILL_WHITE.png" height={20} alt="bump" />
          <span className=" mx-1">
            {order?._id?.substr(order?._id?.length - 4)}
          </span>
        </div>
        <div className="d-flex table-sec header-col">
          <img src="/img/icons/TABLE_WHITE.png" height={20} alt="bump" />
          <span className=" mx-2">
            {order?.tableNumber ? order.tableNumber : tableNo}
          </span>
        </div>
        {order?.order_type !== "served" ? (
          <div className="d-flex tk-sec header-col">
            <img src="/img/icons/TAKEAWAY_WHITE.png" height={20} alt="bump" />
            <span className="mx-2">T</span>
          </div>
        ) : (
          <div className="d-flex bg-light-yellow header-col">
            <img src="/img/icons/INSTORE_WHITE.png" height={20} alt="bump" />
            <span className="mx-2">I</span>
          </div>
        )}
      </div>
    </>
  );
};
export default OcardHeader;
