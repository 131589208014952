/* eslint-disable import/no-anonymous-default-export */
import Repository from './Repository'

const TABLE = '/table-sessions/'
const BUSINESS_ID=localStorage.getItem("blId") || 660076343853236;

export default {
    getAllTables(payload){
        return Repository.get(`floor-plans/${BUSINESS_ID}`)
    },
    getAllTableSessions(payload) {
        return Repository.get(`${TABLE + BUSINESS_ID}`)
    },
    toggleBumpStatus(payload) {
        return Repository.put(`${TABLE}bump/${payload.id}`,payload)
    },
    selectStation(payload){
        return Repository.put(`${TABLE +"station/" + payload.sessionID}`,{
          station: payload.station})
      },
      updataTbSession(payload){
        return Repository.put(`${TABLE}update-session-table`,{
            id:payload.id,
            table_id:payload.table_id
        })
    },
    mergeSessions(payload){
        return Repository.put(`${TABLE +"merge-session/"}`,{
            "firstSession":payload.firstSession,
            "target_session":payload.target_session,
            "table_name":payload.table_name
        }
           )
    }
};