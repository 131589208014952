import React, { useState } from "react";
import "../../assets/css/recipe.css";
import { STEPS_MODAL_OPEN, STEP_MODAL_CLOSE } from "../../store/types";
import { useSelector, useDispatch } from "react-redux/es/exports";
import ModalInterface from "../ModalInterface";
import { Button, Col, Row } from "reactstrap";
const StepsModal = () => {
  const dispatch = useDispatch();

  const [indexOfSetep, setIndexOfStep] = useState(-1);

  const stepModal = useSelector((state) => state?.modal?.stepModal);
  const stepdata = useSelector((state) => state?.modal?.steps);
  function nextStep() {
    if (stepdata.length >= indexOfSetep + 3) {
      setIndexOfStep(indexOfSetep + 1);
    }
  }
  function previousStep() {
    if (indexOfSetep + 1 > 0) {
      setIndexOfStep(indexOfSetep - 1);
    }
  }
  return (
    <>
      <ModalInterface
        display={stepModal}
        // setDisplay={setDisplay}
        size={"lg"}
        className={""}
        content={
          <div className="">
            <div
              className="modalCancelbtn"
              onClick={() => {
                // setDisplay(false);
                dispatch({ type: STEP_MODAL_CLOSE });
              }}
            >
              <img
                src="/img/icons/DENY_WHITE.png"
                width={30}
                height={30}
                alt=""
              />
            </div>

            <div className="p-5 bg-light">
              <img
                src="#"
                width={100}
                height={150}
                alt=""
                className="invisible"
              />
            </div>
            {stepdata !== null && stepdata?.length > 0 ? (
              <>
                <div className="m-3">
                  <h5 className="mt-5">Step {indexOfSetep + 2}</h5>
                  <p style={{ textTransform: "capitalize", fontSize: "12px" }}>
                    {stepdata[indexOfSetep + 1]?.description !== null
                      ? stepdata[indexOfSetep + 1]?.description
                      : "Lorem"}
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "} */}
                  </p>
                </div>
                {/* <div>
              {stepdata?.map((test,index) => {
                return <h6>Step {index}</h6>;
              })}
            </div> */}

                <Row className="text-center mt-5">
                  <Col>
                    <Button
                      size="md"
                      className={indexOfSetep + 1 == 0 ? "invisible" : ""}
                      onClick={() => previousStep()}
                      style={{ backgroundColor: "#1D313A" }}
                    >
                      <span>&lt; Previous </span>
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      size="md"
                      className={
                        stepdata?.length > indexOfSetep + 2 ? "" : "invisible"
                      }
                      onClick={() => nextStep()}
                      style={{ backgroundColor: "#1D313A" }}
                    >
                      <span>Next &gt; </span>
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="text-center text-danger">
                No steps for this recipe
              </div>
            )}
          </div>
        }
        closeEvent={() => {
          //   setDisplay(false);
          dispatch({
            type: STEP_MODAL_CLOSE,
          });
        }}
      />
    </>
  );
};

export default StepsModal;
