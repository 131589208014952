import React from "react";
import { Badge, Col, Row } from "reactstrap";
import ModalInterface from "../ModalInterface";
import "../../assets/css/mergeModal.css";
import { useSelector, useDispatch } from "react-redux";
import { CLEAR_CHANGE_MERGE_MODAL, MERGE_MODAL } from "../../store/types";
import { mergeSession } from "../../store/actions/tableSessionsAction";


const MergeModal = ({ openMergeModal, setOpenMergeModal }) => {
    const dispatch = useDispatch();
    const { tableNo } = useSelector(state => state?.modal);
    const sessions = useSelector((state) => state?.tableSession?.sessions);
    const closeFunc = () => {
        setOpenMergeModal(false)
        dispatch({
            type: CLEAR_CHANGE_MERGE_MODAL,
        })
    }
    const secondSessionFunc = (targetSession) => {
        dispatch({
            type: MERGE_MODAL,
            payload: {
                ...tableNo,
                targeSession: {
                    id: targetSession._id,
                    name: targetSession.table_name,
                }
            }
        })
    }

    const choosedName = (name) => {
        dispatch(mergeSession({
            firstSession: tableNo?.firstTableName?.id,
            target_session: tableNo?.targeSession?.id,
            table_name: name
        }))
        closeFunc();
    }
    return (
        <>
            <ModalInterface
                display={openMergeModal}
                ceneter={true}
                size={"lg"}

                content={
                    <Row className="p-0">
                        <Col md="12" sm="12" xs="12" lg="12">
                            <div className="d-flex justify-content-between align-items-start pb-3">
                                <div className="heading-seesion"> SELECT SESSION</div>
                                <div className="cross-button">
                                    <img
                                        src="/img/icons/DENY_WHITE.png"
                                        width={30}
                                        height={30}
                                        alt=""
                                        onClick={() => { closeFunc() }}
                                    /></div>
                            </div>
                            <div className="session-group p-3">
                                {sessions.map((se, index) => {
                                    return (
                                        <>
                                            {se?._id == tableNo.firstTableName?.id ? (
                                                <Badge color="" pill className="badge-bt muted p-2 mx-2">
                                                    <span className="text-muted">{tableNo.firstTableName?.name}</span>
                                                </Badge>
                                            ) : se?._id == tableNo.targeSession?.id ? (<Badge color="" pill className="badge-bt muted p-2 mx-2">
                                                <span className="text-muted">{tableNo.targeSession?.name}</span>
                                            </Badge>) : (
                                                <Badge
                                                    color="info"
                                                    className=" badg-pointer p-2 m-2"
                                                    pill
                                                    onClick={() => { secondSessionFunc(se) }}
                                                >
                                                    <span>{se?.table_name}</span>
                                                </Badge>
                                            )}
                                        </>
                                    )
                                })}
                            </div>
                            {tableNo?.targeSession ? <div>
                                <div className="d-flex justify-content-between align-items-start py-2">
                                    <div className="heading-seesion">CHOOSE NAME OF SESSION</div>
                                </div>
                                <div className="session-group p-3 d-flex justify-content-center">
                                    <div className="first-session">
                                        <Badge
                                            className=" badg-pointer p-2 m-2"
                                            pill
                                            onClick={() => { choosedName(tableNo?.firstTableName.name) }}
                                        >
                                            <span>{tableNo?.firstTableName.name}</span>
                                        </Badge>
                                    </div>
                                    <div className="second-session">
                                        <Badge
                                            className=" badg-pointer p-2 m-2"
                                            pill
                                            onClick={() => { choosedName(tableNo?.targeSession.name) }}
                                        >
                                            <span>{tableNo?.targeSession.name}</span>
                                        </Badge>
                                    </div>
                                </div>
                            </div> : ""}

                        </Col>
                    </Row>
                }
                closeEvent={() => {
                    setOpenMergeModal(false);
                }}
            />
        </>
    );
};

export default MergeModal;
