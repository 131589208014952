import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "reactstrap";
import "../../assets/css/recipe.css";
import RowRecipe from "../../components/Dashboard/RecipeComponents/RowRecipe";
import { getOrders } from "../../store/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import { getAllStations } from "../../store/actions/stationAction";
import { Loader } from "../../components/Loader";

import { CLEAR_ID, CLEAR_ORDER_ID } from "../../store/types";
import { useLocation } from "react-router-dom";
import HomeView from "./HomeView";

const Recipe = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const data = useSelector((state) => state.orders.orders);
  const orderData = useSelector((state) => state?.orders.processChange);
  const priorityStore = useSelector((state) => state?.orders?.priortyChange);
  const userStations = useSelector(
    (state) => state?.authUser?.user?.data?.stations
  );
  const [idSelect, setIdSelected] = useState("");
  const loading = useSelector((state) => state?.orders?.loading);
  const dishPriority = useSelector((state)=> state?.orders?.dishPriority);
 

  //Order Get
  useEffect(() => {
    // dispatch(getOrders());
    dispatch(getAllStations());
  }, []);


  //Reload UseEffect
  useEffect(() => {
    if (orderData?.success === true || priorityStore?.success === true || dishPriority?.success === true) {
      dispatch(getOrders());
    }
    setIdSelected("")
    dispatch({ type: CLEAR_ID })
    dispatch({ type: CLEAR_ORDER_ID })

  }, [orderData,priorityStore,dishPriority]);
 

  //Filter With Station
  const getFilteredData = (data) => {
    let orders = [];
    data?.map((item) => {
      return item?.salesEntries.map((itx) => {
        return userStations.forEach((station) => {
          if (
            station === itx.station &&
            !orders?.some(
              (single) => single._id === item._id //check to avoid adding duplicate orders
            )
          ) {
            orders.push(item);
          }
        });
      });
    });
    return orders;
  };


  //Loading Div
  if (loading) {
    return(<Loader/>)
   
    //   <>
    //     <div className="d-flex justify-content-center align-items-center">
    //       <iframe
    //         title="loader"
    //         src="https://giphy.com/embed/PUYgk3wpNk0WA"
    //         width="100%"
    //         height="100%"
    //         frameBorder="0"
    //         className="giphy-embed"
    //         allowFullScreen
    //       ></iframe>
    //     </div>
    //   </>
    // );
  }
  const saleEntriesFilter = (item) => {
    const list = location?.state?.menu === "all"
      ? item.salesEntries
      : item.salesEntries.filter(
        (itx) => itx.station === location?.state?.menu
      )
    return list
  }

  return (
    <>

{/* <HomeView /> */}
      <Row className="p-3">
        <Col md="12" sm="12" lg="12" xs="12">
          <Table className="p-3 mt-4 table table-borderless " responsive>
            <thead>
              <tr>
                <th className="thead-gray">
                  <label>ORDER N.</label>
                </th>
                <th className="thead-gray">
                  <label>Start</label>
                </th>
                <th></th>

                <th className="thead-gray">
                  <label>WAITING TIME</label>
                </th>
                <th className="thead-gray">
                  <label>READY</label>
                </th>
              </tr>
            </thead>
            <tbody>
              {getFilteredData(data)?.map((item, index) => {
                var saleItems = saleEntriesFilter(item)
                return (
                  <>
                    {saleItems?.length > 0 &&
                      <RowRecipe
                        item={item}
                        stations={userStations}
                        salesEntries={saleItems}
                        // salesEntries={  location?.state?.menu === "all"
                        // ? item.salesEntries
                        // : item.salesEntries.filter(
                        //     (itx) => itx.station === location?.state?.menu
                        //   )
                        // }
                        idSelect={idSelect}
                        setIdSelected={setIdSelected}

                      // saleEntriesdata={saleEntriesdata}
                      // playbtn={"gray"}
                      // orderNumber={12}
                      // playReady={"gray"}
                      // card1={"gray"}
                      // card2={"gray"}
                      />}
                  </>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

    </>
  );
};

export default Recipe;
