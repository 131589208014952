/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import TableCard from '../../components/Dashboard/TableComponent/TableCard';
import { Col, Container, Row, Spinner } from 'reactstrap';
import '../../assets/css/tableView.css';
import { useDispatch, useSelector } from "react-redux";
import { changeBumpStatus, getAllSessions } from "../../store/actions/tableSessionsAction";
// import { Loader } from "../../components/Loader";
import { getOrdersBySession } from "../../store/actions/orderAction";
import { useNavigate } from "react-router-dom";

const Table = () => {
	const dispatch = useDispatch();
	const sessions = useSelector((state) => state?.tableSession?.sessions);
	const sessionOrders = useSelector((state) => state?.orders?.sessionOrders);
	const [selectedSession, setSelectedSession] = useState("");
	const [priorityOrders, setPriorityOrders] = useState("");
	const [cookedOrders, setCookedOrders] = useState("");
	const [otherOrders, setOtherOrders] = useState("");
	// const [selecTable, setSelectTable] = useState();
	const loading = useSelector((state) => state?.tableSession?.loading);
	const tableChange = useSelector((state) => state?.tableSession?.tableChange);

	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getAllSessions());
	}, []);
	useEffect(() => {
		if (tableChange?.success) {
			dispatch(getAllSessions());
		}
	}, [tableChange])
	useEffect(() => {
		sortSessions()
	}, [sessions]);
	useEffect(() => {
		if (selectedSession !== "") {
			if (sessionOrders.length > 0) {
				navigate("/admin/dashboard", {
					state: { sessionOrders },
				});
				// console.log('redirectt')
			} else {
				alert("No orders currently placed in this session/table");
			}
		}
	}, [sessionOrders]);
	const sortSessions = () => {
		let arr = sessions?.filter((item) => {
			return item?.priority;
		});
		setPriorityOrders(arr);

		arr = sessions?.filter((item) => {
			return !item?.priority && item?.order_status !== "cooked";
		});
		setOtherOrders(arr);

		arr = sessions?.filter((item) => {
			return !item?.priority && item?.order_status === "cooked";
		});
		setCookedOrders(arr);
	}
	const toggleBumpStatus = (payload, type) => {
		if (window.confirm('Do you want to change bump status of this table?')) {
			dispatch(changeBumpStatus(payload))
			if (type === 'cooked') {
				let updated = [...cookedOrders]
				let ind = updated.findIndex((sess) => {
					return sess._id === payload.id
				})
				if (ind !== -1) {
					let table = updated.splice(ind, 1)
					table[0].bump = false;
					updated.unshift(table[0])
					setCookedOrders(updated)
				}
			}
			else if (type === 'other') {
				let updated = [...otherOrders]
				let ind = updated.findIndex((sess) => {
					return sess._id === payload.id
				})
				if (ind !== -1) {
					let table = updated.splice(ind, 1)
					table[0].bump = false;
					updated.unshift(table[0])
					setOtherOrders(updated)
				}
			}
			else if (type === 'priority') {
				let updated = [...priorityOrders]
				let ind = updated.findIndex((sess) => {
					return sess._id === payload.id
				})
				if (ind !== -1) {
					let table = updated.splice(ind, 1)
					table[0].bump = false;
					updated.unshift(table[0])
					setPriorityOrders(updated)
				}
			}
		}
	}
	if (loading) {
		return <>
			<Container>
				<Row className="p-2 mt-5 d-flex justify-content-center align-items-center">
					<Spinner />
				</Row>
			</Container>
		</>
	}
	return (
		<>
			<Container>
				{sessions?.length > 0 ? (
					otherOrders?.length > 0 && (
						<Row className="p-2 mt-5">
							<Col md="6" lg="8" sm="12" xs="12" xl="8" xxl="6">
								{/* This Row for not cooked and not priority table*/}
								<Row>

									{otherOrders?.map((table) => {
										return (
											<Col lg="3" md="6" sm="6">
												<TableCard
													color1stTile={
														table?.bump
															? "bgNo-green"
															: "bgNo-grey"
													}
													color2ndTile={"bgTable-blue"}
													color3Tile={
														table?.order_status === "cooked"
															? "bgTable-green"
															: table?.order_status === "cooking"
																? "bgTable-orange"
																: "bgNo-grey"
													}

													number={table?.table_id}
													id={table?._id}
													name={table?.table_name}
													orderType={table?.order_type}
													setSelectedSession={() => {
														setSelectedSession(table);
														dispatch(getOrdersBySession(table));
													}}
													// setSelectTable={setSelectTable}
													// selecTable={selecTable}
													changeBumpStatus={() => { if (table?.bump) toggleBumpStatus({ id: table?._id, status: false }, 'other') }}
													station={table?.station}
												/>
											</Col>
										);
									})
									}

								</Row>

							</Col>

							<Col sm="12" md="6" xs="12" lg="4" xl="4" xxl="4">
								<Row>
									{cookedOrders?.length > 0 &&
										cookedOrders?.map((table) => {

											return (
												<Col lg="6" sm="6">
													<TableCard
														color1stTile={
															table?.bump
																? "bgNo-green"
																: "bgNo-grey"
														}
														color2ndTile={"bgTable-blue"}
														color3Tile={"bgTable-green"}
														id={table?._id}
														number={table?.table_id}
														name={table?.table_name}
														orderType={table?.order_type}
														setSelectedSession={() => {
															setSelectedSession(table);
															dispatch(getOrdersBySession(table));
														}}
														changeBumpStatus={() => { if (table?.bump) toggleBumpStatus({ id: table?._id, status: false }, 'cooked') }}
														station={table?.station}
													/>
												</Col>

											);
										})}
								</Row>
							</Col>
							<Col sm="12" md="12" xs="12" lg="12" xl="12" xxl="2">
								{/* This Row for Priority Dishes */}
								<Row>
									{priorityOrders?.length > 0 &&
										priorityOrders?.map((table) => {
											return (
												<Col lg="12">
													<TableCard
														color1stTile={
															table?.bump
																? "bgNo-green"
																: "bgNo-pink"
														}
														// color1stTile={"bgNo-pink"}
														color2ndTile={"bgTable-blue"}
														color3Tile={
															table?.order_status === "cooked"
																? "bgTable-green"
																: table?.order_status === "cooking"
																	? "bgTable-orange"
																	: "bgNo-grey"
														}
														number={table?.table_id}

														name={table?.table_name}
														orderType={table?.order_type}
														setSelectedSession={() => {
															setSelectedSession(table);
															dispatch(getOrdersBySession(table));
														}}
														changeBumpStatus={() => { if (table?.bump) toggleBumpStatus({ id: table?._id, status: false }, 'priority') }}
													/>
												</Col>

											);
										})}
								</Row>
							</Col>
						</Row>
					)
				)
					:
					(
						<div className="py-5 d-flex justify-content-center align-items-center">
							<span className="text-muted">No Sessions Avaialable</span>
						</div>
					)
				}

			</Container>
		</>
	);
};

export default Table;

{
	/* <Row className='p-2 mt-5'>
  <Col lg='9'>
	  <Row className='mb-1'>
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={16}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={36}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={25}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={18}
		  />
		  <TableCard
			  color1stTile={'bgNo-green'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={1}
		  />
		  <TableCard
			  color1stTile={'bgNo-green'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={13}
		  />
	  </Row>
	  <Row className='mb-1'>
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={1}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={9}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={3}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={4}
		  />
		  <TableCard
			  color1stTile={'bgNo-green'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={2}
		  />
		  <TableCard
			  color1stTile={'bgNo-green'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={9}
		  />
	  </Row>
	  <Row className='mb-1'>
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={7}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={44}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={14}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={11}
		  />
		  <TableCard
			  color1stTile={'bgNo-green'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={4}
		  />
	  	
	  </Row>
	  <Row className='mb-1'>
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={54}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={15}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={49}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={33}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={7}
		  />
	  	
	  </Row>
	  <Row className='mb-1'>
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={21}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={28}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={42}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={46}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={17}
		  />
	  	
	  </Row>
	  <Row className='mb-2'>
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={29}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={30}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={6}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={39}
		  />
		  <TableCard
			  color1stTile={'bgNo-grey'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={50}
		  />
	  	
	  </Row>
  </Col>
  <Col lg='3'>
	  <div className='ml-5'>
		  <TableCard
			  color1stTile={'bgNo-pink'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={1}
		  />
		  <TableCard
			  color1stTile={'bgNo-pink'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={2}
		  />
		  <TableCard
			  color1stTile={'bgNo-pink'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={4}
		  />
		  <TableCard
			  color1stTile={'bgNo-pink'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={7}
		  />
		  <TableCard
			  color1stTile={'bgNo-pink'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-green'}
			  number={17}
		  />
		  <TableCard
			  color1stTile={'bgNo-pink'}
			  color2ndTile={'bgTable-blue'}
			  color3Tile={'bgTable-orange'}
			  number={50}
		  />
	  </div>
  </Col>
  </Row> */
}
