import Repository from './Repository';

const RECIPE = '/recipes'

export default {
	getRecipe(payload) {
		return Repository.get(`${RECIPE}`);
	},
	getSingleRecipe(payload) {
		return Repository.get(`${RECIPE + `/`+ payload}`);
	},
	getLatestRecipe(payload) {
		return Repository.get(`${RECIPE + `?isCache=false`}` );
	},
	
};