/* eslint-disable react-hooks/exhaustive-deps */
import Slider from "react-slick";
import "../../../../src/assets/css//slick.css";
import "../../../../src/assets/css/slick-theme.css";
import { useEffect, useState } from "react";
// import ModalInterface from "../../ModalInterface";
import "../../../../src/assets/css/modalAction.css";
import { useLocation } from "react-router-dom";
// import Draggable from 'react-draggable';
// import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { Cards } from "./Cards";
// import { CardsContainer } from "./CardsContainer";
import { useDispatch, useSelector } from "react-redux";
import { getCourses, getOrders, getSingleOrder } from "../../../store/actions/orderAction";
import ChangeStationModal from "./ChangeStationModal/changeStation";
import MangeModal from "./MangeModal/manage";
import StepsModal from "../../Modals/stepsModal";
import { Spinner } from "reactstrap";
import { CLEAR_ID, CLEAR_ORDER_ID } from "../../../store/types";
import { settings } from "../../../assets/sliderSetting";

export const Home = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.orders.orders);
  const userStations = useSelector((state) => state?.authUser?.user?.data?.stations);
  const orderUpdated = useSelector((state) => state?.orders?.updateStatus);
  const StUpdate = useSelector((state) => state?.orders?.stChange);
  const StatusChange = useSelector((state) => state?.orders?.processChange);
  const changePriority = useSelector((state) => state?.orders?.priortyChange);
  const cancelResponse = useSelector((state) => state?.orders?.cancelOrder);
  const removeitem = useSelector((state) => state?.orders?.removeItem);
  const dishPriority = useSelector((state) => state?.orders?.dishPriority);
  const updatedSingleOrder = useSelector((state) => state?.orders?.singleOrder);
  const singleOrderLoader = useSelector((state) => state?.orders?.singleLoading);
  const loading = useSelector((state) => state?.orders?.loading);

  // const errorOrder = useSelector((err) => err);
  const [display, setDisplay] = useState(false);
  const location = useLocation();
  const [stationItem, SetStationItem] = useState(false);
  var selected_menu = location?.state?.menu;
  const [saleEntrySelected, setSaleEntrySelected] = useState("");
  const [order, setOrder] = useState(false);
  const [singleOrder, setSingleOrder] = useState('')
  const [removedOrder, setRemovedOrder] = useState('')

  if (order === true) {
    setTimeout(() => {
      setOrder(false);
    }, 5000);
  }
  //  <--------------------End of Scoket---------------->
  if (selected_menu === undefined && location.pathname === "/admin/dashboard") {
    selected_menu = "all";
  }
  function fetcFunc() {
    dispatch(getOrders());
    dispatch(getCourses());
  }
  useEffect(() => {
    fetcFunc();
  }, []);


  useEffect(() => {
    if (cancelResponse.success) {
      setRemovedOrder(cancelResponse?.orderID)
    }
  }, [cancelResponse])



  // ORDER UPDATE STATUS CHECK OR STATION UPDATE
  useEffect(() => {
    if (
      orderUpdated?.success === true ||
      StUpdate?.success === true ||
      StatusChange.success === true ||
      changePriority.success === true ||
      removeitem.success === true ||
      dishPriority?.success === true
      //cancelresponse.success
    ) {
      // dispatch(getOrders());
      dispatch(getSingleOrder(singleOrder));
    }
    dispatch({ type: CLEAR_ID });
    dispatch({ type: CLEAR_ORDER_ID });
  }, [
    orderUpdated,
    StUpdate,
    StatusChange,
    changePriority,
    removeitem,
    dishPriority,
  ]);
  // Employee Path By Default Tab SETTING
  if (
    location?.state?.menu === undefined &&
    location.pathname === "/employee/dashboard"
  ) {
    selected_menu = userStations[0];
  }
  //Filter Process for card
  const getFilteredData = (dataArr) => {
    //this function dynamically removes/updates items from the array without reloading all orders
    //to cater single updated order and re-render individual updated order
    //seperate cases for admin and employee have all been catered, please check block comments for details
    let data = [...dataArr]
    if (updatedSingleOrder?._id !== undefined || updatedSingleOrder?._id !== null || cancelResponse?.orderID !== '') {
      //for employee we only re-render the selected sale entry
      if (location.pathname.includes("employee")) {
        let orderIndex = data?.findIndex((item) => { return item._id === updatedSingleOrder?._id })
        if (orderIndex !== -1) {//remove item
          if (removeitem?.success) {
            let itemIndex = data[orderIndex]?.salesEntries.findIndex((item) => { return item._id === removeitem?.payload?.saleEntryID })
            if (itemIndex !== -1)
              data[orderIndex].salesEntries.splice(itemIndex, 1)
          }
          else {//update item
            let oldIndex = data[orderIndex]?.salesEntries?.findIndex((item) => { return item._id === saleEntrySelected })
            let updatedItemIndex = updatedSingleOrder?.salesEntries?.findIndex((item) => { return item._id === saleEntrySelected })
            if (oldIndex !== -1 && updatedItemIndex !== -1 && oldIndex !== undefined) {
              data[orderIndex].salesEntries[oldIndex] = updatedSingleOrder?.salesEntries[updatedItemIndex]
            }
          }

        }
      }
      else {
        //for admin re-render the entire selected order
        let ind = data.findIndex((item) => {
          return item._id === updatedSingleOrder?._id;
        });
        if (ind !== -1) {
          if (removeitem?.success) {
            //to remove sale entry
            let itemIndex = data[ind]?.salesEntries.findIndex((item) => { return item._id === removeitem?.payload?.saleEntryID })
            if (itemIndex !== -1) {
              if (data[ind]?.salesEntries.length === 1) {
                //remove entire order if deleted and only a single sale entry
                //TODO handle this on backend api
                data.splice(ind, 1)
              } else {
                data[ind].salesEntries.splice(itemIndex, 1)
              }
            }
            // }
          } else {//update order only
            data[ind] = updatedSingleOrder
          }
        }
        if (cancelResponse?.success) { //remove order entirely
          let ind = data.findIndex((item) => { return item._id === removedOrder })
          if (ind !== -1) {
            data.splice(ind, 1)
            // setRemovedOrder('')
          }
        }
      }
    }
    if (location?.state?.sessionOrders?.length > 0) {
      return location?.state?.sessionOrders;
    } else if (
      selected_menu === "all" &&
      !location.pathname.includes("employee")
    ) {
      return data; //return all orders
    } else if (location.pathname.includes("employee")) {
      var tempOrders = [];
      data.forEach((itx) => {
        if (itx.salesEntries.length > 1) {
          itx.salesEntries.forEach((itx2) => {
            var obj = { ...itx, salesEntries: [itx2] };
            tempOrders.push(obj);
          });
        } else {
          var obj = { ...itx, salesEntries: [itx.salesEntries[0]] };
          tempOrders.push(obj);
        }
      });

      // let arr = tempOrders?.filter((item) => {
      //   let obj = item?.salesEntries.find((itx) => itx.station === selected_menu);
      //   if (obj != null) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // });

      //employee can only see allocated station items in selectedMenu=all
      if (selected_menu === "all") {
        let arr = [];
        tempOrders?.map((order) => {
          return order?.salesEntries?.map((item) => {
            userStations.forEach((station) => {
              if (item?.station === station) arr.push(order);
              return;
            });
          });
        });
        return arr;
        // return tempOrders;
      } else {
        let arr = tempOrders?.filter((item) => {
          return item?.salesEntries?.some(
            (itx) => itx?.station === selected_menu
          );
        });
        return arr;
      }
    } else {
      let arr = data?.filter((item) => {
        return item?.salesEntries?.some((itx) => itx?.station === selected_menu);
      });

      return arr;
    }
  };

  // //Loading Div
  // if (loading && data.length  > 1 ) {
  //   return (
  // <>
  //     <div className="d-flex position-absolute top-50 start-50">
  //     <Spinner />
  //   </div>
  //     </>
  //   );
  // }

  // if (loading) {
  //   return (
  //     <>
  //       <div className="d-flex align-items-center justify-content-center mt-5">
  //         <div className="d-flex position-absolute top-50 start-50">
  //           <Spinner />
  //         </div>
  //       </div>

  //     </>

  //   );
  // } else

  if (loading) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center mt-5">
          {/* <span className="text-muted">There are no data found...</span>
           */}
          <Spinner />
        </div>
      </>

    );
  }
  else if (data?.length === 0) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center mt-5">
          <span className="text-muted">There are no data found...</span>


        </div>
      </>
    )
  }
  else {
    return (
      <>
        {/* {location.pathname.includes("employee") ? (
        <HomeView getFilteredData={getFilteredData} data={data}  selected_menu={selected_menu}/>
      ) : (
        */}
        {/* {data.length === 0 ? <div className="my-5 d-flex align-items-center justify-content-center text-muted "><p>There are no orders to show</p></div> : */}
        <Slider {...settings} className="mb-5 ml-2" style={{ overflow: "hidden" }}>
          {getFilteredData(data).map((item, index) => {
            return (
              <Cards
                item={item}
                setDisplay={setDisplay}
                orderNumber={item.posId}
                selected_menu={selected_menu}
                orderTable={item.tableNumber}
                // orderType={item.orderType}
                // deliveryType={item.deliveryType}
                orders={
                  selected_menu === "all"
                    ? item?.salesEntries
                    : item?.salesEntries?.filter(
                      (itx) => itx.station === selected_menu
                    )
                }
                cardId={item._id}
                stationIs={stationItem}
                SetStationItem={SetStationItem}
                sessionID={item?.sessionId}
                ordrType={item?.order_type}
                createdAt={item.createdAt}
                saleEntrySelected={saleEntrySelected}
                setSaleEntrySelected={setSaleEntrySelected}
                cookingStarted={
                  item?.cooking_started ? item?.cooking_started : false
                }
                key={index}
                setUpdateOrder={() => {
                  setSingleOrder(item)
                }}
                //only show card loader if current card was selected
                loading={(singleOrder?._id === item?._id && singleOrderLoader)}
                clearSelection={saleEntrySelected === ''}

              />
            );
          })}
        </Slider>


        <MangeModal />
        <ChangeStationModal SetStationItem={SetStationItem} />
        <StepsModal />
      </>
    );
  }


};
