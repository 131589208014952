import * as services from "../Type/ServicesTypes";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var ServiceNotificationRepository = RepositoryFactory.get("service");


export const getServiceNotification = (payload) => async (dispatch) => {
    try {
        dispatch({type:services.LOADING});
        const res = await ServiceNotificationRepository.getServiceNotifications(payload);
        dispatch({
          type: services.FETCH_SERVICES_NOTIFICATIONS,
          payload: res.data,
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: services.FAIL_SERVICES,
          payload: error,
        });
      }
}

export const saveServiceNotifications = (payload) => async (dispatch) => {
  try {
    dispatch({type:services.LOADING});
    const res = await ServiceNotificationRepository.addServiceNotifications(payload);
    dispatch({
      type: services.SAVE_SERVICE_NOTIFICATION,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: services.FAIL_SERVICES,
      payload: error,
    });
  }
};
