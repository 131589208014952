import React from "react";
import { Col, Row } from "reactstrap";
import ModalInterface from "../ModalInterface";
import "../../assets/css/selectStationMdoal.css";
import { useDispatch } from "react-redux";
import {
  bumpOrderAction,
  ProcessCookingAction,

} from "../../store/actions/orderAction";
import { changeBumpStatus,  selectStation, } from "../../store/actions/tableSessionsAction";
const SelectStation = ({ stationSlectModal, setStationSelect, orderObj }) => {
  const dispatch = useDispatch();
  const cookedFunc = (word) => {
    dispatch(
        selectStation({
            sessionID: orderObj?.order?.sessionId,
            station: word,
        }
    ))
    dispatch(
      ProcessCookingAction({
        order_id: orderObj?.order?._id,
        item_id: orderObj?.dish?._id,
        status: "cooked",
      })
    );
    dispatch(
      bumpOrderAction({
        order_id: orderObj?.order?._id,
        bumped: true,
        item_id: orderObj?.dish?._id,
      })
    )
      dispatch(
        changeBumpStatus({
          id: orderObj?.order?.sessionId,
          status: true,
          
        })
      )
    setStationSelect(false);
  };
  return (
    <>
      <ModalInterface
        display={stationSlectModal}
        ceneter={true}
        size={"lg"}
        bodyClassName={"stationselect-modal"}
        content={
          <Row className="p-0">
            <Col md="12" sm="12" xs="12" lg="12">
              <div className="title p-0"> SELECT STATION</div>
              <Row className="p-3">
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("A")}
                  >
                    A
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("1")}
                  >
                    1
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("B")}
                  >
                    B
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("2")}
                  >
                    2
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("C")}
                  >
                    C
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("3")}
                  >
                    3
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("D")}
                  >
                    D
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("4")}
                  >
                    4
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("E")}
                  >
                    E
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("5")}
                  >
                    5
                  </div>
                </Col>
                <Col md="2" sm="2" xs="2" lg="2">
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("F")}
                  >
                    F
                  </div>
                  <div
                    className="station-block"
                    onClick={() => cookedFunc("6")}
                  >
                    6
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        closeEvent={() => {
          setStationSelect(false);
        }}
      />
    </>
  );
};

export default SelectStation;
