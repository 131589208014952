import React, { useState } from "react";
// import { bumpOrderAction } from "../../../store/actions/orderAction";y
import "./ocard.css";
import { useDispatch } from "react-redux";
// import { changeBumpStatus } from "../../../store/actions/tableSessionsAction";
const OrderFooter = ({
  cooked,
  // setCookingModalObj,
  // order,
  dish,
  // setCookingModalOpen,
  createdAt,
  cookingStarted,
  cookingFunc
}) => {
  const orderCreation = new Date(createdAt);
  const cookingStartedTime = cookingStarted ? new Date(cookingStarted) : false;
  const [current, setCurrent] = useState(new Date());
  const dispatch = useDispatch();
  function refreshTime() {
    if (dish?.status !== "cooked") {
      setCurrent(new Date());
    }
  }
  setTimeout(refreshTime, 1000);

  const bumpFunc = () => {
    if (window.location.pathname !== "admin/dashboard/single-order") {
      cookingFunc()
      //     if (dish?.status === "cooked") {
      //       dispatch(bumpOrderAction({
      //         order_id: order?._id,
      //         bumped: true,
      //         item_id: dish?._id
      //       }))
      //       dispatch(changeBumpStatus({ id: order?.sessionId, status:true }))
      //     }
      //   }else
      //   {
      // console.log('this will only work when in recall.')
      //   }
    }
  }

  function msConverter(diff) {
    if (diff < 0) {
      //in some cases cooking time out of sync so adjust time
      diff += 50000; //30sec time adjustment
    }
    var msec = Math.abs(diff);
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }
    if (hh === "00")
      return mm + ":" + ss;
    return hh + ":" + mm + ":" + ss;
  }
  return (
    <div className=" d-flex justify-content-between card-footer">
      <div className="d-flex order-time bg-gray footer-col">
        <span>{msConverter(current - orderCreation) && "00:00"}</span>
      </div>
      <div
        className={dish?.bumped === true && dish?.status === 'cooked' ? `d-flex dish-sec bg-green` : `d-flex dish-sec bg-gray`}
        onClick={() => {
          bumpFunc()
        }}
      >
        <img src="/img/icons/BUMP_WHITE.png" height={30} alt="bump" />
      </div>
      <div className={`d-flex over-time  ${cooked} footer-col`}>
        <img src="/img/icons/BUMP_WHITE.png" height={20} alt="bump" />
        <span>
          {!cookingStartedTime
            ? // <CountDown />
            "00:00"
            : msConverter(current - cookingStartedTime)}
        </span>
      </div>
    </div>
  );
};

export default OrderFooter;
