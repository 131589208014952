import { RECIPE_FETCH, RECIPE_FAILL, RECIPE_SINGLE_FETCH, RECIPE_REQUEST, RECIPE_RESET } from '../types';
import { RecipeDummy } from '../../DummyData/recipeDummy';
import { singleRecipe } from '../../DummyData/singleRecipe';


import { RepositoryFactory } from "../../repository/RepositoryFactory";

var recipeRepositoroy = RepositoryFactory.get('recipe');
export const getAllRecipe = () => {
    console.log(RecipeDummy, 'im in action')
};
export const getRecipeAction = (payload) => async (dispatch) => {

    try {

        const res = await recipeRepositoroy.getRecipe();
        dispatch({
            type: RECIPE_FETCH,
            payload: res.data.data
        })
    }
    catch (error) {
        console.log(error)
        dispatch({
            type: RECIPE_FAILL,
            payload: error,
        })
    }
}

export const getSingleRecipe = (payload) => async (dispatch) => {
    try {
        dispatch({ type: RECIPE_REQUEST })
        const res = await recipeRepositoroy.getSingleRecipe(payload);
        dispatch({
            type: RECIPE_SINGLE_FETCH,
            payload: res?.data?.data
        })
    }
    catch (error) {
        dispatch({
            type: RECIPE_FAILL,
            payload: error,
        })
    }
}

export const refreshRecipes = (payload) => async (dispatch) => {
    try {
        dispatch({ type: RECIPE_RESET })
        const res = await recipeRepositoroy.getLatestRecipe();
        dispatch({
            type: RECIPE_FETCH,
            payload: res.data.data
        })
    }
    catch (error) {
        console.log(error)
        dispatch({
            type: RECIPE_FAILL,
            payload: error,
        })
    }
}