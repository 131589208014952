// Root Reducer

import { combineReducers } from 'redux';
import authUser from './authUser';
import { stationReducer } from './stationReducer'
import { orderReducer } from './oderReducer';
import { recipeReducer } from './recipeReducer';
import { IdReducer } from './idReducer';
import { modalReducer } from './modalReducer';
import { manage_modalReducer } from './manageModalReducer';
import { tableSessionsReducer } from './tableSessionsReducer';
import {servicesReducer} from './servicesReducer';


export let rootReducer = combineReducers({
	authUser: authUser,
	stations: stationReducer,
	orders: orderReducer,
	recipes: recipeReducer,
	info: IdReducer,
	modal: modalReducer,
	manage: manage_modalReducer,
	tableSession: tableSessionsReducer,
	services:servicesReducer
});

export default rootReducer;
