import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import {
  bumpOrderAction,
  ProcessCookingAction,
  toggleBarLoader,
} from "../../../store/actions/orderAction";
import { changeBumpStatus } from "../../../store/actions/tableSessionsAction";
import { CLEAR_ORDER_ID, GET_ID} from "../../../store/types";
import OcardHeader from "./ocardHeader";
import OrderFooter from "./ofooter";
const MultiOrders = ({ order, setUpdateOrder, SingleLoading }) => {
  const idStore = useSelector((store) => store?.info?.id);
  const dispatch = useDispatch();
  const [cookingStatus, SetCookingStatus] = useState("");
  //===============ITem Cooking Process===============
  const dishProcess = () => {
    setUpdateOrder({
      _id: order?._id,
      bar: true,
      // dish_id: item?._id,
      businessLocationId: order?.businessLocationId,
    });
    dispatch(toggleBarLoader(true))
    order?.salesEntries?.forEach((item, index) => {
      if (item?.station === "bar") {
        if (item?.status === "pending") {
          dispatch(
            ProcessCookingAction({
              order_id: order?._id,
              item_id: item?._id,
              status: "cooking",
            })
          );
        }
        else if (item?.status === "cooking") {
          dispatch(
            ProcessCookingAction({
              order_id: order?._id,
              item_id: item?._id,
              status: "cooked",
            })
          )
          dispatch(
            bumpOrderAction({
              order_id: order?._id,
              bumped: true,
              item_id: item?._id,
            })
          ).them(()=>{
            dispatch(
              changeBumpStatus({
                id:order.sessionId,
                status: true,  
              })
            )
          })
      
        }
      }
    });
    // dispatch(toggleBarLoader(false))
  };
  //=====================================================================
  //=================ITem Select for PRIORITY And DELTEION===============
  const itemSelect = (id, item) => {
    if (item?.payment_status === "paid" || item?.payment_status === "half_paid") {
      alert("This item has been paid");
    } else {
      setUpdateOrder({
        _id: order?._id,
        dish_id: item?._id,
        businessLocationId: order?.businessLocationId,
      });
      dispatch({
        type: GET_ID,
        payload: { Id: id, stationName: item },
      });
      dispatch({ type: CLEAR_ORDER_ID });
    }
  };
  //=================================
  useEffect(() => {
    const allCooked = order?.salesEntries?.every((x) => {
      return x?.status === "cooked";
    });
    const paid = order?.salesEntries?.every((x) => {
      return x?.payment_status === "paid" || x?.payment_status === "half_paid";
    });
    const cookingOne = order?.salesEntries?.some((x) => {
      return x?.status === "cooking";
    });
    const pendingsome = order?.salesEntries?.some((x) => {
      return x?.status === "pending";
    });
    const allPending = order?.salesEntries?.every((x) => {
      return x?.status === "pending";
    });
    if (allCooked === true) {
      SetCookingStatus("bgplaygreen");
    } else if (cookingOne) {
      SetCookingStatus("bgorder-yellow");
    } else if (allPending) {
      SetCookingStatus("bgOrder-light-Gray");
    } else if (paid) {
      SetCookingStatus("bgplaygreen");
    }
    else if (pendingsome) {
      SetCookingStatus("bgOrder-light-Gray");
    }
  }, [order]);
  return (
    <>
      <div className="o-card-head">
        <div className="content-card">
          <OcardHeader
            // dish={di}
            order={order}
          //   tableNo={order?.tableNumber}
          //   setSingleOrder={setSingleOrder}
          />
          <div className="takeaway-sec d-flex justify-content-between">
            <span>{order?.order_type?.toUpperCase()}</span>
            <span>
              {order?.sessionName !== null ? order?.sessionName : "12:45"}
            </span>
          </div>
          <div className="o-card-body">
            {SingleLoading === true  ?(
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Spinner />
              </div>
            ) : (
              <>
                {order?.salesEntries.map((di, index) => {
                  if (di?.station === "bar" && (di?.status ==="cooking" || di?.status ==="pending"))
                    return (
                      <>
                        <div
                          className={
                            di?.priority === "urgent"
                              ? "item-sec bghotpink d-flex align-items-center justify-content-between pl-2"
                              : "item-sec d-flex align-items-center justify-content-between pl-2"
                          }
                        >
                          <span
                            className="item-name-ocard f-14 px-1"
                            onClick={() => {
                              dishProcess(di);
                            }}
                          >
                            {di?.itemName !== null
                              ? di?.itemName?.toUpperCase()
                              : "KAMUT BASE"}
                          </span>
                          <div onClick={() => { itemSelect(order?._id, di) }}
                            className={
                              di?.status === "cooked" || di?.payment_status === "half-paid" || di?.payment_status === "paid"
                                ? `item-station bg-green`
                                : di?.status === "cooking"
                                  ? `item-station bg-yellow`
                                  : `item-station bg-gray`
                            }
                          >
                            <span>{di?.station?.toUpperCase()}</span>
                          </div>
                        </div>
                        <div className=" order-body pb-5">
                          <div
                            className={
                              idStore?.stationName?._id === di?._id
                                ? "item-overlay"
                                : "abc"
                            }
                          ></div>
                          <div className="dishName d-block px-2">
                            {di?.subLineItems.map((sl, index) => {
                              return (
                                <>
                                  {sl.type === "Standard" &&
                                    sl.isSingleOnly === true ? (
                                    <>
                                      <div className="d-flex ">
                                        <img
                                          src="/img/icons/MIDARROWRIGHT_BLACK.png"
                                          className="pt-1"
                                          height={20}
                                          alt="item-logo"
                                        />
                                        <span className="f-14 px-1">
                                          {sl?.itemName}
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                          </div>
                          {di?.redItems?.length > 0 &&
                            di?.greenItems?.length > 0 ? (
                            <div className="two-card-sec d-flex justify-content-between">
                              <div className="block-card bg-pink">
                                <span>
                                  {" "}
                                  <img
                                    src="/img/icons/MINUS_BLACK.png"
                                    alt="minus"
                                    height={12}
                                  />
                                </span>
                                {di?.redItems?.map((item, key) => {
                                  return (
                                    <div className="card-porduct pl-2">
                                      <span className="d-block ">
                                        {item?.itemName}{" "}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="block-card bg-lightGreen">
                                <span>
                                  {" "}
                                  <img
                                    src="/img/icons/PLUS_BLACK.png"
                                    alt="minus"
                                    height={12}
                                  />
                                </span>
                                {di?.greenItems?.map((item, key) => {
                                  return (
                                    <div className="card-porduct pl-2">
                                      <span className="d-block ">
                                        {item?.itemName}{" "}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="ingredients-sec mt-1 ">
                            <div className="bill-img">
                              <img
                                src="/img/icons/BILL_GRAY.png"
                                height={20}
                                alt="bill"
                              />
                            </div>
                            {di?.ingredients?.map((ing, key) => {
                              return (
                                <div className="ing-name px-2">
                                  <span className=" d-block">
                                    <span>{parseInt(ing?.quantity)} -</span>{" "}
                                    {ing?.name}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    );
                })}
              </>
            )}
          </div>
          <div className=" d-block align-items-end footer">
            <OrderFooter
              // setCookingModalObj={setCookingModalObj}
              // setCookingModalOpen={setCookingModalOpen}
              cooked={cookingStatus}
              createdAt={order?.createdAt}
              cookingStarted={
                order?.cooking_started ? order?.cooking_started : false
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default MultiOrders;
