import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { CLEAR_ID, ORDER_ID } from "../../../store/types";
import OrderListCard from "./OrderListCard";

const OrderContainer = ({ data }) => {
  const [cardExpand, setCardExpand] = useState(-1);
  const dispatch = useDispatch();
  const location = useLocation();

  var selected_menu = location?.state?.menu;
  const [current, setCurrent] = useState(new Date())
  const orderId = useSelector((state) => state?.info?.oId?.Id)
  function refreshTime() {
    setCurrent(new Date())
  }
  setTimeout(refreshTime, 1000); //refresh every second

  if (selected_menu === undefined && location.pathname === "/admin/dashboard/order-list") {
    selected_menu = "all"
  }


  const svgMore = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-caret-down-fill"
      viewBox="0 0 16 16"
    >
      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
    </svg>
  );
  const svgLess = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-caret-up-fill"
      viewBox="0 0 16 16"
    >
      <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
    </svg>
  );

  // const saleEntriesdata = data.map((item, index) => {
  //   return item.salesEntries
  // })



  const getFilteredData = (data) => {
    if (selected_menu === 'all') {
      return data //return all orders
    }
    let arr = data.filter((item) => {
      return item?.salesEntries.some(itx => itx.station === selected_menu)
    })
    return arr
  }

  const orderSelectFunc = (item) => {

    dispatch({
      type: ORDER_ID,
      payload: {
        Id: item._id,
        priority: item?.priority,
      },

    });
    dispatch({ type: CLEAR_ID })

  }
  const timeFormatter = (elapsed) => {
    // return Math.floor(elapsed / 60) + '’' + Math.floor(elapsed - Math.floor(elapsed / 60) * 60)
    var hours = Math.floor(elapsed / 3600);
    elapsed %= 3600;
    var minutes = Math.floor(elapsed / 60);
    var seconds = Math.floor(elapsed % 60);
    if (hours < 10) {
      hours = "0" + hours
    }
    if (minutes < 10) {
      minutes = "0" + minutes
    }
    if (seconds < 10) {
      seconds = "0" + seconds
    }
    return hours + "'" + minutes + "'" + seconds;
  }
  return (
    <>
         {getFilteredData(data).map((item, index) => {

        var maxPrep = 0;
        var startTime = 0;
        var elapsedTime = 0;
        var delayFlag = false;
        var orderColor = 'pending'

        if (item?.cooking_started) {
          startTime = new Date(item?.cooking_started)
          delayFlag = true;
        }
        //to manage order colors
        const allCooked = item?.salesEntries?.every((x) => {
          return x?.status === "cooked";
        });
        const anyCooking = item?.salesEntries?.some((x) => {
          return x?.status === "cooking";
        });
        if (anyCooking) {
          orderColor = "#F8B133" //orange
        }
        else if (allCooked) {
          orderColor = "#21C100" //green
        }
        else {
          orderColor = '#f1f1f1' //gray
        }
        return (
          <Row className="orderbg_list container-fluid m-2 mt-4 p-2 mb-5">
            <Col lg="3" md="3" sm="12" xs="12">
              <div className="orderlist_left  mt-2">
                <ul>
                  <li className={orderId === item?._id ? "lft_bgdarkblue left_bgdarkblue_select" : "lft_bgdarkblue"} onClick={() => orderSelectFunc(item)}>{item?._id?.substr(item?._id?.length - 4)}</li>
                  <li className="lft_bglightblue">{item?.tableNumber}</li>
                  <li className="lft_bgyellow">
                    <img src="/img/icons/INSTORE_BLACK.png" alt="imgIcon" />I
                  </li>
                  {/* <li className="lft_bggrey">Q1</li> */}
                </ul>
                {/* <div
                  className={cardExpand !== index ? `moreOrder ` : `moreOrder active`} onClick={() => { console.log('eheheh') }}
                >
                  {cardExpand !== index ? (
                    <span className="" onClick={() => { setCardExpand(index) }}>MORE {svgMore}</span>
                  ) : (
                    <span size="md" color="secondary" onClick={() => { setCardExpand(-1) }}>
                      LESS {svgLess}
                    </span>
                  )}
                </div> */}
                {cardExpand !== index ?
                  (<div
                    className={`moreOrder `} onClick={() => { setCardExpand(index) }}
                  >
                    <span>MORE {svgMore}</span>
                  </div>)
                  : (
                    <div
                      className={`moreOrder active`} onClick={() => { setCardExpand(-1) }}
                    >
                      <span size="md" color="secondary">
                        LESS {svgLess}
                      </span>
                    </div>)}
              </div>
            </Col>
            <Col lg="7" md="7" sm="12" xs="12">
              <div
                className={
                  cardExpand !== index
                    ? "orderlist_detail coll_close  mt-2"
                    : "orderlist_detail  mt-2"
                }
              >
                <ul style={{ listStyle: "none" }}>
                {item?.salesEntries.flat()?.map((subitem, index) => {
                    if (subitem?.preperation_time > maxPrep) {
                      maxPrep = subitem?.preperation_time //prep time is in seconds
                      // maxPrep = maxPrep / 60; //seconds to min
                    }
                    if (delayFlag) {
                      elapsedTime = (current - startTime) / 1000 //elapsed time in seconds
                    }
                    return (
                      <li>
                        {selected_menu === "all" || subitem.station === selected_menu ?
                          <OrderListCard
                            key={index}
                            item={subitem}
                            orderId={item?._id}
                            power={subitem.station[0]?.toUpperCase()}
                            color={allCooked ? "#21C100" : anyCooking ? "#F8B133" : "#f1f1f1"}
                            priority={item.priority} />
                          : null}
                      </li>
                    )
                  })

                  }
                  {/* <li>
                  <OrderListCard power={"P1"} color={"green"} />
                </li>
                <li>
                  <OrderListCard power={"P3"} />
                </li>
                <li>
                  <OrderListCard power={"P3"} />
                </li>
                <li>
                  <OrderListCard power={"P3"} />
                </li>
                <li>
                  <OrderListCard power={"P3"} />
                </li> */}
                </ul>
              </div>
            </Col>

            <Col lg="2" md="2" sm="12" xs="12" className="d-flex justify-content-end  ">
              <div className="delaytime_order  mt-2" >
                <ul>
                  <li className="dishBg_blue mx-1" style={{ borderBottom: `8px solid ${orderColor}` }}>
                    <span>
                      <img src="/img/icons/BUMP_WHITE.png" alt="whiteImg" />{" "}
                      <ion-icon name="chevron-forward"></ion-icon>
                    </span>
                  </li>
                  <li>{timeFormatter(maxPrep === 0 ? maxPrep + 10 : maxPrep + (maxPrep * 0.1))}</li>
                  <li>{delayFlag && elapsedTime > maxPrep ? timeFormatter(elapsedTime - maxPrep) : '00’00’00'}</li>
                </ul>
              </div>
            </Col>
          </Row>
        )

      })
      }
    </>
  );
};
export default OrderContainer;
