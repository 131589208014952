/* eslint-disable import/no-anonymous-default-export */
// import { DISH_PRIORITY } from "../store/Type/orderTypes";  
import Repository from "./Repository";

const ORDER = "/order/";
const businessId = localStorage.getItem("blId") || 660076343853236;


export default {
  getOrders(payload) {
    return Repository.get(`${ORDER + `test-orders/${businessId}`}`);
  },
  getSingleOrder(payload) {
    return Repository.get(
      `${
        ORDER +
        "single-order/" +
        payload._id +
        "?blId=" +
        businessId
      }`
    );
  },
  updateOrder(payload) {
    return Repository.put(`${ORDER + payload?.id}`, payload?.finalStatus);
  },
  chanageTheSt(payload) {
    return Repository.put(`${ORDER}`, payload);
  },
  chanageCookingProcess(payload) {
    return Repository.put(`${ORDER + "change-item-status"}`, payload);
  },
  ordrPriorityRepo(payload) {
    return Repository.put(`${ORDER + "change-order-priority"}`, payload);
  },
  cancelOrderRepo(payload) {
    return Repository.delete(`${ORDER + "cancel-order/" + payload}`);
  },
  removeItemRepo(payload) {
    return Repository.delete(
      `${ORDER + payload.orderId + "?item_id=" + payload.saleEntryID}`
    );
  },
  getOrdersBySession(payload) {
    return Repository.get(
      `${ORDER + "session-orders/" + payload._id + `?blId=${businessId}`}`,
      payload
    );
  },
  dishPriority(payload) {
    return Repository.put(`${ORDER + "change-item-priority"}`, payload);
  },
  bumpOrder(payload) {
    return Repository.put(`${ORDER + "bump-order"}`, payload);
  },

  getCourses() {
    return Repository.get(`courses`);
  },
};
