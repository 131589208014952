import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import { MERGE_MODAL } from '../../../store/types';

const TableCard = ({ color1stTile, color2ndTile, color3Tile, id, number, name, orderType, setSelectedSession, changeBumpStatus, station }) => {
	const dispatch = useDispatch();
	const { tableNo } = useSelector(state => state?.modal)
	const setTableToMerge = () => {

		dispatch({
			type: MERGE_MODAL,
			payload: {
				firstTableName: {
					id,
					number,
					name
				}
			}
		})
	}
	return (
		<>
			<Col md='2' sm='6' lg='3' xs='6'>
				<div className='table-card' onClick={changeBumpStatus}>
					<div
						className={
							tableNo?.firstTableName?.id === id ? "item-overlay" : "abc"
						}
					></div>
					<div className={`numberSec ${color1stTile} p-1`} style={{ textAlign: "center" }} onClick={() => { setTableToMerge(id) }}>
						<span
							style={color1stTile === "bgNo-pink" ? { color: 'white' } : { color: "black" }}>

							{name + "    " + number} &nbsp; &nbsp; &nbsp;
							{station}
						</span>
					</div>
					<div>
					</div>
					<div className='partionSec'>
						<div className={`resTb-on  ${color2ndTile}`} onClick={setSelectedSession} style={{ cursor: "pointer" }}>O</div>
						<div className={`resTb-so ${color3Tile}`}>{orderType === "served" ? "S" : "P"}</div>
						<div></div>
					</div>
				</div>
			</Col>
		</>
	);
};

export default TableCard;
