import { FETCH_TABLES ,FETCH_SESSIONS, SESSIONS_FAIL, SESSIONS_REQUEST, TABLE_CHANGE } from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { SELECT_STATION } from "../Type/orderTypes";

// import TableRepository from "../../repository/TableRepository";
var tableRepository = RepositoryFactory.get("table");

export const getAllTableAction=(payload)=> async(dispatch)=>{
  try{
   dispatch({ type: SESSIONS_REQUEST });
   const response = await tableRepository.getAllTables();

   dispatch({
     type: FETCH_TABLES,
     payload: response?.data?.data,
   });
  }
  catch(error){
    console.log("Error fetching Table Sessions", error);
    dispatch({
      type: SESSIONS_FAIL,
      payload: error,
    });
  }
};

export const getAllSessions = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SESSIONS_REQUEST });
    const response = await tableRepository.getAllTableSessions();
    dispatch({
      type: FETCH_SESSIONS,
      payload: response?.data?.data,
    });
  } catch (error) {
    console.log("Error fetching Table Sessions", error);
    dispatch({
      type: SESSIONS_FAIL,
      payload: error,
    });
  }
};
export const changeBumpStatus = (payload) => async (dispatch) => {
  try {
    var tableRepository = RepositoryFactory.get("table");
    dispatch({ type: SESSIONS_REQUEST });
    await tableRepository.toggleBumpStatus(payload);
  } catch (error) {
    console.log("Error fetching Table Sessions", error);
    dispatch({
      type: SESSIONS_FAIL,
      payload: error,
    });
  }
};
export const selectStation = (payload) => async (dispatch) => {
  try {
    let { data } = await tableRepository.selectStation(payload);
    dispatch({
      type: SELECT_STATION,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: SESSIONS_FAIL, payload: error });
  }
};
export const UpdataTableSession = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SESSIONS_REQUEST });
    let { data } = await tableRepository.updataTbSession(payload);

    dispatch({
      type: TABLE_CHANGE,
      payload: data
    })
  }
  catch (error) {
    dispatch({ type: SESSIONS_FAIL, payload: error });
  }
}
export const mergeSession =(payload)=> async (dispatch) =>{
try{
  dispatch({type:SESSIONS_REQUEST})
  let { data } = await tableRepository.mergeSessions(payload);
  console.log(data,'the merge data')
    dispatch({
      type: TABLE_CHANGE,
      payload: data
    })
}
catch(error){

  alert("Error !"+ error?.response?.data?.message)
  dispatch({ type: SESSIONS_FAIL, payload: error });
}
}
