// import './App.css';
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./views/Login";
import ManagerDashboard from "./views/Manager/ManagerDashboard";
import EmployeeDashboard from "./views/Employee/EmployeeDashboard";
import HomeView from "./views/Manager/HomeView";
import OrderList from "./views/Manager/OrderList";
import Table from "./views/Manager/Table";
import Recipe from "./views/Employee/Recipe";
import RecipeList from "./views/Employee/RecipeList";
import { useDispatch } from "react-redux";
import { getOrders } from "./store/actions/orderAction";
import { socket } from "./services/services.sockets";
import SingleOrder from "./views/SingleOrder";
import notification from "./notification.mp3";
import NotificationServices from "./components/Dashboard/notificationServices/notificationServices"
import ServiceNotification from "./components/Modals/servicenotify";

function App() {
  const dispatch = useDispatch(); 
  const [order, setOrder] = useState(false);
  // const [order1, setOrder1] = useState(true);
  const [notifyModal, setNotifyModal] = useState(false);
  const [notifyMsg, setNotifyMsg]= useState("");
  const audioPlayer = useRef(null);
  const blID = localStorage.getItem("blId") || 660076343853236;
  // Notification FUnction
  const notify = () => {
    const audio = new Audio(notification);
    if (audio !== undefined) {
      audio.play();
    }
  };
  //Sockets Receiving Packets
  useEffect(() => {

    socket.on("problem_received", async (data) => {
      setNotifyMsg(data);
      setNotifyModal(true);
    });
    socket.on("order_received", () => {
      notify();
      console.log("notify-----");
      dispatch(getOrders());
      setOrder(true);
    });
  }, []);

  if (order === true) {
    setTimeout(() => {
      setOrder(false);
    }, 5000);
  }
 
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />

          <Route path="/Admin/dashboard" element={<ManagerDashboard />}>
            <Route
              path="/Admin/dashboard/:MenuName"
              element={<ManagerDashboard />}
            ></Route>
            <Route index element={<HomeView />} />
            <Route path="orders" element={<HomeView />} />

            <Route path="order-list" element={<OrderList />}></Route>
            <Route path="tables" element={<Table />}></Route>
            <Route path="single-order" element={<SingleOrder />}></Route>
            <Route path="service-notification" element={<NotificationServices />}></Route>
          </Route>

          <Route path="/Employee/dashboard" element={<EmployeeDashboard />}>
            <Route
              path="/Employee/dashboard/:MenuName"
              element={<EmployeeDashboard />}
            ></Route>
            <Route index element={<HomeView />} />
            <Route path="orders" element={<HomeView />} />
            <Route path="order-list" element={<Recipe />}></Route>
            <Route path="tables" element={<Table />}></Route>

            {/* <Route path='recipe' element={<Recipe />}></Route> */}
            <Route path="single-order" element={<SingleOrder />}></Route>

            <Route path="recipe-list" element={<RecipeList />}></Route>
            <Route path="recipe-list/:id" element={<RecipeList />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <audio ref={audioPlayer} src={notification} />
      <ServiceNotification notifyModal={notifyModal} setNotifyModal={setNotifyModal}  notifyMsg={notifyMsg} blID={blID}/>
    </div>
  );
}

export default App;
